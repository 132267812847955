import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { User } from '../../models/User';
import { IntercomService } from '../intercom/intercom.service';
import { UserService } from '../user/user.service';

@Injectable()
export class AuthService {
  private authenticated: boolean;
  isAuthenticated: Observable<boolean>;
  // store the URL so we can redirect after logging in
  redirectUrl: string; // ???NOT USED???

  constructor(
    private http: HttpClient,
    private router: Router,
    private intercomServ: IntercomService,
    private userServ: UserService
  ) {
    // check if it is authenticated
    this.isAuthenticated = new Observable<boolean>(observer => {
      if (this.authenticated) {
        return observer.next(true);
      }

      // check if it is authenticated
      const URI = `${environment.apiUrl}/user`;
      this.http.get(URI).subscribe((data: User) => {
        // user is not authenticated
        if (!data._id) {
          this.userServ.removeUser();
          return observer.next(false);
        }

        // user is authenticated
        this.userServ.checkLanguage(data);
        this.userServ.setUser(data);
        this.authenticated = true;
        return observer.next(true);
      });
    });

    // calling it to load the user on first page load
    this.isAuthenticated.subscribe();
  }

  public signup(user) {
    const URI = `${environment.apiUrl}/user/signup`;
    return this.http.post(URI, user);
  }

  public signin(username: string, password: string) {
    const URI = `${environment.apiUrl}/user/signin`;
    return this.http.post(URI, { username: username, password: password });
  }

  public signout() {
    const URI = `${environment.apiUrl}/user/signout`;
    return this.http.get(URI).subscribe((data) => {
      const user = this.userServ.getUser();

      // Tracking 'signOut' event on Intercom.
      this.intercomServ.trackEvent(user, 'signOut');

      // User deauthentication and redirection.
      this.userServ.removeUser();
      this.authenticated = false;
      this.router.navigate(['/']);

      // Shutting down Intercom
      this.intercomServ.shutdown(true);
      /* PREV:
      return setTimeout(() => {
        this.intercomServ.shutdown(false);
        location.reload(true);
      }, 500);
      */
    });
  }

  public passwordforgot(username: string, callback: Function) {
    const URI = `${environment.apiUrl}/user/password/forgot`;
    return this.http.post(URI, { username: username }).subscribe((data: any) => {
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  public validatetoken(token: string, callback: Function) {
    const URI = `${environment.apiUrl}/user/password/token`;
    return this.http.post(URI, { token: token }).subscribe((data: any) => {
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  public passwordreset(user: any, callback: Function) {
    const URI = `${environment.apiUrl}/user/password/reset`;
    return this.http.post(URI, user).subscribe((data: any) => {
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // Used to redirect users based on their groups
  public redirectUserByGroup() {
    const user = this.userServ.getUser();
    let redirUrl: string = '/';
    if (user) {
      redirUrl = '/dashboard';
      if (user.isInterpreter()) {
        redirUrl = '/dashboard/interpreter';
      } else if (user.isAdmin()) {
        redirUrl = '/admin';
      }
    }
    return this.router.navigate([redirUrl]);
  }
}
