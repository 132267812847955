import {TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID, MissingTranslationStrategy, CompilerOptions} from '@angular/core';

// use the require method provided by webpack to return content of file as string
declare const require;

export function getTranslationProviders(): CompilerOptions {
  let locale = 'en';

  // Getting browser language
  const browserLanguage = navigator.language.slice(0, 2);
  if (browserLanguage && (
    (browserLanguage === 'es') ||
    (browserLanguage === 'it')
  )) {
    locale = browserLanguage;
  }

  // Getting user's websiteLanguage (same value in database user document)
  if (localStorage['userWebsiteLanguage'] && (localStorage['userWebsiteLanguage'].length === 2)) {
    locale = localStorage.getItem('userWebsiteLanguage');
  } else {
    // Incognito mode does not allow Storage on some browsers
    try {
      localStorage.setItem('userWebsiteLanguage', locale);
    } catch (err) {
      console.log('i18n Provider getTranslationProviders try.localStorage.setItem ERROR! err.message:', err.message);
    }
  }

  const localePath = 'messages.' + locale;

  // No locale or English: no translation providers
  let translations = [];
  if (localePath !== 'messages.en') {
    // we use the webpack raw-loader to return the content as a string
    translations = require(`raw-loader!./i18n/${localePath}.xlf`);
  }

  return {
    missingTranslation: MissingTranslationStrategy.Ignore,
    providers: [
      {provide: TRANSLATIONS, useValue: translations},
      {provide: TRANSLATIONS_FORMAT, useValue: 'xlf2'},
      {provide: LOCALE_ID, useValue: locale}
    ]
  };
}
