import { Component, Input, OnInit } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { EventService } from '../../../../services/event/event.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  public uploader: FileUploader;
  @Input() url: string;
  @Input() disabled: boolean;

  constructor(private eventServ: EventService, private flashMessagesServ: FlashMessagesService) {
  }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: this.url,
      autoUpload: true
    });
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    const event = JSON.parse(response);
    this.eventServ.getCurrentEvent().briefingMaterials = event.briefingMaterials;
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let err = { message : 'unknown error' };
    try {
      err = JSON.parse(response);
    } catch (e) {
      if (response.indexOf('413 Request Entity Too Large') !== -1) {
        err.message = 'Max size 10MB';
      } else {
        console.log(response);
        this.flashMessagesServ.error(response);
      }
    }

    this.flashMessagesServ.error('Error uploading document: ' + err.message);
  }
}
