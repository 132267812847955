import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { User } from '../../../models/User';
import { AuthService } from '../../../services/auth/auth.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: User;
  availableLangs = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'it', label: 'Italiano' }
  ];
  websiteLang = localStorage.getItem('userWebsiteLanguage') || 'en';
  private subscription: Subscription;

  constructor(private authServ: AuthService, private userServ: UserService) {
  }

  ngOnInit() {
    this.subscription = this.userServ.userChange.subscribe((user) => {
        this.user = user;
    });
  }

  public onSignout() {
    this.authServ.signout();
  }

  public setLanguage = (language) => {
    if (this.user) { // users
      // status: ['newlycreated', 'invited', 'active', 'sleeping', 'unpaid-billing', 'inreview', 'suspended', 'blocked', 'abusive']
      if (['newlycreated', 'invited', 'sleeping'].indexOf(this.user.status) > -1)  {
        this.user.status = 'active';
      }
      this.user.websiteLanguage = language;
      this.userServ.update(this.user, (err, data) => {
        if (err) {
          console.error('HeaderComponent.setLanguage Error saving user websiteLanguage. err:', err);
        } else {
          console.log('HeaderComponent.setLanguage Success saving user websiteLanguage.');
          this.userServ.checkLanguage(data);
        }
      });
    } else { // visitors
      try {
        localStorage.setItem('userWebsiteLanguage', language);
        console.log('HeaderComponent.setLanguage  locale set to:', language);
        location.reload(true);
      } catch(e) {
        console.error('HeaderComponent.setLanguage e.message:', e.message);
      }
    }
  }
}
