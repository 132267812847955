import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { SocketsService } from "../../../../services/sockets/sockets.service";
import { FlashMessagesService } from "../../../../services/util/flash-messages.service";
import { KurentoService } from "../../../../services/kurento/kurento.service";

@Component({
  selector: 'app-no-connection-modal',
  templateUrl: './no-connection-modal.component.html',
  styleUrls: ['./no-connection-modal.component.css']
})
export class NoConnectionModalComponent implements OnInit {

  private subscriptions = new Subscription();
  websocketEnabled = true;
  @ViewChild('noConnectionEventModal') noConnectionEventModal;

  constructor(private socketsServ: SocketsService, private flashMessagesServ: FlashMessagesService) { }

  ngOnInit() {
    this.subscriptions.add(KurentoService.websocketStatusChange.subscribe((value) => {
      if (value !== this.websocketEnabled) {
        const elem: any = document.getElementById('noConnectionEventModal');
        if (!value) {
          if (elem) this.noConnectionEventModal.show();
          //this.flashMessagesServ.warning('A connection issue has been detected. Please wait…');
        } else {
          if (elem) this.noConnectionEventModal.hide();
          this.flashMessagesServ.success('Connection re-established');
        }
      }
      this.websocketEnabled = value;
    }));
  }

}
