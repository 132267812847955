import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { Event, InterpreterStatus } from '../../models/Event';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable()
export class EventService {
  eventChange: Subject<Event> = new BehaviorSubject<Event>(null);
  private events: Event[] = [];
  private event: Event;
  oldEvent: Event;
  private kindOfVenues: Array<string> = [
    'Ballroom',
    'Bar',
    'Conference Center',
    'Garden',
    'Hotel',
    'Indoor',
    'Meeting Room',
    'Museum',
    'Outdoor',
    'Restaurant',
    'Stadium',
    'Theater',
    'University'
  ];

  constructor(private http: HttpClient, private router: Router) {
    router.events.forEach((routeEvent) => {
      if (routeEvent instanceof NavigationStart) {
        // Returning event to not saved version after route changes on event setup
        if ((this.oldEvent) && (routeEvent.url.indexOf('event') !== -1) && (routeEvent.url.indexOf('setup') !== -1)) {
          this.event = JSON.parse(JSON.stringify(this.oldEvent));
          this.eventChange.next(this.event);
        }
      }
    });
  }

  getKindOfVenues(): Array<string> {
    return this.kindOfVenues;
  }

  public getCurrentEvent(): Event {
    return this.event;
  }

  public getEvents(): Event[] {
    return this.events;
  }

  public setEvents(data: Event[]) {
    this.events = data;
  }

  // Create a new event
  // calls: 'save' => 'saveEvent'
  public createEvent(event: Event, callback: Function) {
    const URI = `${environment.apiUrl}/event`;
    return this.http.post(URI, event).subscribe((data: Event) => {
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  public setEvent(event: Event) {
    this.event = event;
    this.oldEvent = JSON.parse(JSON.stringify(event));
    this.eventChange.next(this.event);
  }

  // Update an event
  // calls: 'save' => 'saveEvent'
  public updateEvent(event: Event, callback: Function) {
    const URI = `${environment.apiUrl}/event`;
    return this.http.put(URI, event).subscribe((data: Event) => {
      this.setEvent(data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // Get all unexpired events assigned to an interpreter
  // calls: 'getUnexpiredForInterpreter' => 'getAllUnexpiredEventsByInterpreterId'
  public loadInterpreterEvents(callback: Function) {
    const URI = `${environment.apiUrl}/event/interpreter`;
    return this.http.get(URI).subscribe((data: Event[]) => {
      this.setEvents(data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // loadUserEvents
  // calls: 'getAllUserEvents' => 'getAllEvents'
  public loadUserEvents(query: any, callback: Function) {
    const URI = `${environment.apiUrl}/event/linked`;
    return this.http.post(URI, query).subscribe((data: Event[]) => {
        this.setEvents(data);
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  // Delete event by id
  // calls: 'delete' => 'getEventById'
  public deleteEvent(id: string, callback: Function) {
    const URI = `${environment.apiUrl}/event/${id}`;
    return this.http.delete(URI).subscribe((data: any) => {
      console.log('data:', data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // Get event by id
  // calls: 'getById' => 'getEventById'
  public getEvent(id: string, callback: Function) {
    const URI = `${environment.apiUrl}/event/${id}`;
    return this.http.get(URI).subscribe((data: Event) => {
      this.setEvent(data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // Subscribe event by id
  // calls: 'subscribe' => severals...
  public subscribeEvent(eventId: string, callback: Function) {
    const URI = `${environment.apiUrl}/event/${eventId}/subscribe`;
    return this.http.put(URI, {}).subscribe((data: Event) => {
        this.event = data;
        this.oldEvent = JSON.parse(JSON.stringify(data));
        this.eventChange.next(this.event);
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  // Get event by code
  // calls: 'getByCode' => 'getEventByCode'
  public getByCode(code: string, callback: Function) {
    const URI = `${environment.apiUrl}/event/code/${code}`;
    return this.http.get(URI).subscribe((data: Event) => {
      this.setEvent(data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // ???NOT USED??? SAME AS THE ABOVE (getByCode) except for 'apiUrlUnsecure'
  public getByCodeAudience(code: string, callback: Function) {
    const URI = `${environment.apiUrlUnsecure}/event/code/${code}`;
    return this.http.get(URI).subscribe((data: Event) => {
      this.setEvent(data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // Method to check if the lang is already on air for another booth
  // calls: 'isLangOnAir' => 'interpreter.broadcast.server.controller'
  public isLangOnAir(langCode: string, langPair: string, callback: Function) {
    if (!this.event) {
      return callback({ message: 'Event not set' })
    }
    const URI = `${ environment.apiUrl }/event/${ this.event._id }/langonair`;
    return this.http.post(URI, { langCode: langCode, langPair: langPair }).subscribe((data: Event) => {
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  /* ???TODO???:
  public loadEventManagers(id: string, callback: Function) {
    const URI = `${environment.apiUrl}/event/${id}/managers`;
    return this.http.get(URI).subscribe((data: Event[]) => {
      this.setEvents(data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }
  */

  // Get all events
  // calls: 'getAll' => 'getAllEvents'
  public getAllEvents(callback: Function) {
    const URI = `${environment.apiUrl}/event/events/list`;
    return this.http.get(URI).subscribe((data: Event[]) => {
      this.setEvents(data);
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  // Get db and collection statisctics
  // calls: 'stats' => 'getAllEvents'
  public getStats(callback: Function) {
    const URI = `${environment.apiUrl}/event/events/stats`;
    return this.http.get(URI).subscribe((data: any) => {
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  public putHttpPorts(eventId: string, languageCode: string, ports: number[], callback: Function) {
    const URI = `${environment.apiUrl}/event/${eventId}/ports`;
    return this.http.put(URI, {}).subscribe((data: Event) => {
        this.event = data;
        this.event.languages.forEach(item => {
          if(item.languageCode === languageCode)
            item.ports = ports;
        });
        this.oldEvent = JSON.parse(JSON.stringify(data));
        this.eventChange.next(this.event);
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  // Remove pending interpreters (which are added only for view purposes)
  public removePendingInterpreters() {
    for (let i = 0; i < this.event.booths.length; i++) {
      if (_.get(this.event.booths[i], 'interpreters.length')) {
        for (let j = 0; j < this.event.booths[i].interpreters.length; j++) {
          if (this.event.booths[i].interpreters[j].status === InterpreterStatus.PENDING) {
            this.event.booths[i].interpreters.splice(j, 1);
          }
        }
      }
    }
  }

  public getNameOfAblioInterpreter(id) {
    if (!id || !this.event || !this.event.booths) {
      return;
    }
    for (let i = 0; i < this.event.booths.length; i++) {
      const booth = this.event.booths[i];
      if (!booth || !booth.interpreters) {
        return;
      }
      for (let j = 0; j < booth.interpreters.length; j++) {
        const interpreter = booth.interpreters[j];
        if (interpreter && interpreter.ablio && ((interpreter.user === id) || interpreter.ablio.ablioId === id)) {
          return interpreter.ablio.firstName + ' ' + interpreter.ablio.lastName;
        }
      }
    }
  }

  public setEndDate(event: Event, numbOfDays) {
    if (event.date) {
      const parts = event.date.split('-');
      if (numbOfDays && (numbOfDays > 1)) {
        const de = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
        de.setDate(de.getDate() + (numbOfDays - 1));
        event.endDate = de.getFullYear() + '-' + (((de.getMonth() + 1) < 10) ? '0' : '') + (de.getMonth() + 1) + '-' + ((de.getDate() < 10) ? '0' : '') + de.getDate();
      } else {
        event.endDate = event.date;
      }
    }
  }
}
