import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { AuthService } from '../../../services/auth/auth.service';
import { IntercomService } from '../../../services/intercom/intercom.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  user: any;
  error;

  constructor(
    private router: Router,
    private authServ: AuthService,
    private intercom: Intercom,
    private intercomServ: IntercomService,
    private userServ: UserService
  ) {
  }

  ngOnInit() {
    this.user = {
      username: '',
      password: ''
    };
  }

  public onSubmit() {
    this.authServ.signin(this.user.username, this.user.password).subscribe((data) => {
      const user = data['user'];
      this.userServ.checkLanguage(user);
      this.userServ.setUser(user);

      // ng-intercom update to send information data about the user like location by ip address
      this.intercom.update({
        user_id: user.ablioId || user._id,
        email: user.email
      });
      this.intercomServ.trackEvent(user, 'signIn');

      if (this.authServ.redirectUrl) {
        return this.router.navigateByUrl(this.authServ.redirectUrl);
      }

      return this.router.navigate(['/dashboard']);
    }, (err) => {
      console.error('SigninComponent.onSubmit.authServ.signin ERROR! err:', err);
      this.error = err.error;
      setTimeout(() => { this.error = null; }, 5000);
    });
  }
}
