import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../../models/User';
import { UserService } from '../../../../services/user/user.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';

@Component({
  selector: 'app-add-to-balance',
  templateUrl: './add-to-balance.component.html',
  styleUrls: ['./add-to-balance.component.css']
})
export class AddToBalanceComponent implements OnInit {
  @Input() modal: any;
  @Input() userId: string;
  user: User;
  disable: boolean = true;
  amount: number = 0;
  data: any = { amount: 0 };
  error: any;

  constructor(
    private router: Router,
    private userServ: UserService,
    private flashMessagesServ: FlashMessagesService
  ) {
  }

  ngOnInit() {
    this.amount = 0;
    this.disable = true;
    this.user = Object.assign({}, this.userServ.getUser());
    this.data = { user: this.user, amount: 0 };
    /*  OR:
    this.data = {
      user: {
        _id : this.user._id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        username: this.user.username,
        groups: this.user.groups,
        paymentMethod: this.user.paymentMethod,
        balance: this.user.balance
      },
      amount: 0
    };
    */
  }

  onSubmit() {
    //if (this.error !== ' ') return;
    if (this.user) {
      this.data.user = this.user;
    } else {
      return;
    }

    if (this.amount && (this.amount > 0)) {
      this.data.amount = this.amount;
    } else {
      return;
    }

    this.disable = true;
    this.userServ.rechargeBalance(this.data, (err, data) => {
      if (err) {
        console.log(err);
        // Inform the customer that there was an error.
        this.error = err.error.message;
        this.disable = false;
        this.modal.hide();

        if (err.error.message === 'Missing payment method') {
          this.router.navigate(['/user/change-payment']);
        }

        return this.flashMessagesServ.error('Error doing transfer: ' + err.error.message);
      }

      this.amount = 0;
      this.data.amount = 0;
      this.flashMessagesServ.success('Successfully transferred money to your credit balance', { grayOut: true });
      this.modal.hide();
    });
  }

  amountChanged() {
    this.data.amount = 0;
    this.disable = true;
    if (this.amount && (this.amount > 0)) {
      this.data.amount = this.amount;
      this.disable = false;
    }
  }
}
