import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { BoothMate, ChatThread } from '../../models/ChatThread';
import { Event } from '../../models/Event';
import { EventService } from '../event/event.service';
import { SocketsService } from '../sockets/sockets.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService implements OnDestroy {
  chatThreads = new Map<string, ChatThread>();
  boothMate: BoothMate;
  event: Event;
  chatThreadsChange: Subject<Map<string, ChatThread>> = new BehaviorSubject<Map<string, ChatThread>>(null);
  newMessageChange: BehaviorSubject<ChatThread> = new BehaviorSubject<ChatThread>(null);
  ready: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private subscriptions = new Subscription();
  private socketMsgRcvSubscription;

  constructor(private eventServ: EventService, private socketsServ: SocketsService) {
    // The chat works only for events by now, it needs the event id to join users to their groups
    this.subscriptions.add(this.eventServ.eventChange.subscribe((event) => {
      if (!event || (this.event && (event._id === this.event._id))) {
        return;
      }

      this.event = event;

      this.subscriptions.add(this.socketsServ.ready.subscribe((isReady) => {
        if (!isReady) {
          this.ready.next(false);
          return;
        }

        this.chatThreadsChange.next(this.chatThreads);
        if (this.socketMsgRcvSubscription) {
          this.socketMsgRcvSubscription.unsubscribe();
        }
        this.socketMsgRcvSubscription = this.socketsServ.socketMessageReceive.subscribe((data) => {
          this.processReceivedMessage(data);
        });
        this.subscriptions.add(this.socketMsgRcvSubscription);
        this.joinGroups();
        this.ready.next(true);
      }));
    }));
  }

  joinGroups() {
    // remove user from previous groups
    this.userOffline();
    this.chatThreads.clear();
    this.chatThreadsChange.next(this.chatThreads);
    this.newMessageChange.next(null);
    // find interpreter booth
    for (let i = 0; i < this.event.booths.length; i++) {
      const booth = this.event.booths[i];
      // owner & master
      // managers: [venueManager, systemManager, interpretersManager, audienceAppManager]
      if ((this.socketsServ.user._id === this.event.eventMaster) || (this.socketsServ.user._id === this.event.owner)) {
        const boothGroupId = this.event._id + booth._id;
        const hardName = booth.langDesc1.substring(0, 3) + '-' + booth.langDesc2.substring(0, 3);
        if (!this.chatThreads.get(boothGroupId)) {
          this.chatThreads.set(boothGroupId, { hardName: hardName, messages: [], isGroup: true });
        }
        this.socketsServ.joinGroup(boothGroupId);
        this.chatThreadsChange.next(this.chatThreads);
      }

      for (let j = 0; j < booth.interpreters.length; j++) {
        // interpreter
        if (booth.interpreters[j].user && (booth.interpreters[j].user.toString() === this.socketsServ.user._id)) {
          // booth found
          // setting booth group
          const boothGroupId = this.event._id + booth._id;
          if (!this.chatThreads.get(boothGroupId)) {
            this.chatThreads.set(boothGroupId, { hardName: 'Booth Group', messages: [], isGroup: true });
          }
          this.socketsServ.joinGroup(boothGroupId);
          this.chatThreadsChange.next(this.chatThreads);
          // setting booth mate
          j = (j === 0) ? 1 : 0;
          if (!booth.interpreters[j] || !booth.interpreters[j].user) {
            // no booth mate
            return;
          }
          const boothMateUserId = booth.interpreters[j].user.toString();
          if (!this.chatThreads.get(boothMateUserId)) {
            this.chatThreads.set(boothMateUserId, { hardName: 'Booth Mate', messages: [], online: false });
          }
          this.boothMate = {
            online: false,
            id: boothMateUserId
          };

          return;
        }
      }
    }
  }

  processReceivedMessage(data) {
    if (!data) {
      return;
    }

    if (data.messageId === 'connectedToServer') {
      this.socketsServ.joinGroup(this.event._id);
    }
    if (data.messageId === 'chatMessage') {
      const socketId = data.isGroup ? data.to : data.from;
      let chatThread = this.chatThreads.get(socketId);
      if (!chatThread) {
        // if sender 'from' is unknown, create new entry in the map of ChatSockets
        // by now it can only be the Booth Mate because is the only one who can send direct messages
        this.chatThreads.set(socketId, { hardName: 'Booth Mate', messages: [], online: true });
        chatThread = this.chatThreads.get(socketId);
      }
      // add message to the chat with 'from'
      chatThread.messages.push({ user: data.fromName, text: data.text });
      if (chatThread.unreadMessage) {
        chatThread.manyUnreadMessages = true;
      }
      chatThread.unreadMessage = true;
      this.chatThreadsChange.next(this.chatThreads);
      this.newMessageChange.next(chatThread);
    }
    if (!this.chatThreads.get(data.from)) {
      return;
    }
    if (data.messageId === 'chatUserOnline') {
      this.chatThreads.get(data.from).online = true;
      this.chatThreads.get(data.from).name = data.fromName;
      this.chatThreadsChange.next(this.chatThreads);
      if (data.from === this.boothMate.id) {
        this.boothMate.online = true;
      }
    }
    if (data.messageId === 'chatUserOffline') {
      this.chatThreads.get(data.from).online = false;
      this.chatThreadsChange.next(this.chatThreads);
      if (data.from === this.boothMate.id) {
        this.boothMate.online = false;
      }
    }
  }

  sendChatMessage(text, receiver) {
    const message = {
      messageId: 'chatMessage',
      text: text,
      from: this.socketsServ.user._id,
      fromName: this.socketsServ.user.username,
      to: receiver.id,
      isGroup: receiver.isGroup
    };
    this.socketsServ.sendMessage(message);
    if (!this.chatThreads.get(message.to)) {
      this.chatThreads.set(message.to, { messages: [] });
    }
    this.chatThreads.get(message.to).messages.push({ user: message.fromName, text: message.text });
    this.chatThreadsChange.next(this.chatThreads);
  }

  userOffline() {
    if (!this.socketsServ.user) {
      return;
    }
    this.socketsServ.sendMessage({
      messageId: 'chatUserOffline',
      from: this.socketsServ.user._id
    });
  }

  clearBlinkingOnTake() {
    const latestChatThread = this.newMessageChange.value;
    if (latestChatThread && latestChatThread.messages && (latestChatThread.messages.length > 0) && (latestChatThread.messages[latestChatThread.messages.length - 1].text === 'PLEASE TAKE THE MIC')) {
      if (!latestChatThread.manyUnreadMessages) {
        latestChatThread.unreadMessage = false;
      }
      this.chatThreadsChange.next(this.chatThreads);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
