import { Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  AsYouType,
  CountryCallingCode,
  CountryCode,
  ParsedNumber,
  formatNumber,
  getCountryCallingCode,
  isValidNumber,
  parseNumber
} from 'libphonenumber-js';
import { CompleterData, CompleterItem, CompleterService } from 'ng2-completer';
import { CountryService } from '../../../../services/util/country.service';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor, Validator {
  private _number: any = ''; // hidden model, used to save the full number in the db
  private _countryCode: CountryCode; // 2 digits country code
  private _formattedNumber: string; // formatted number showed in the input
  dataService: CompleterData; // used by ng2-completer to load data
  countryCallingCode: CountryCallingCode; // prefix number showed in the input e.g: +1
  @Input() disabled: boolean;
  @Input() phoneRequired: boolean;
  // NOT USED: @Input() phoneValidity: boolean;

  constructor(
    private completerServ: CompleterService,
    private countryServ: CountryService
  ) {
    // getting the list of countries and passing it to the ng2-completer lib
    this.dataService = completerServ.local(countryServ.getCountries(), 'name', 'name');
  }

  private static _parseNumber(number, countryCode = null): ParsedNumber {
    if (countryCode) {
      return <ParsedNumber>parseNumber(number, countryCode);
    }

    return <ParsedNumber>parseNumber(number);
  }

  // listening for item selected (set in phone-input.component.html)
  onItemSelect(selected: CompleterItem) {
    if (selected) {
      this.countryCode = selected.originalObject.code;
    }
  }

  // listening for on blur country (set in phone-input.component.html)
  onBlurCountry() {
    this.onTouched();
    try {
      getCountryCallingCode(this._countryCode);
    } catch (e) {
      // removing what the user typed if it is not a valid country code
      this._countryCode = null;
    }
  }

  get countryCode(): CountryCode {
    return this._countryCode;
  }

  // called when user changes country code
  set countryCode(countryCode: CountryCode) {
    if (countryCode !== this._countryCode) {
      this._countryCode = countryCode;
      try {
        this.countryCallingCode = getCountryCallingCode(countryCode);
        try {
          const parsedNumber = PhoneInputComponent._parseNumber(this._formattedNumber, this._countryCode);
          this.number = formatNumber(parsedNumber, 'E.164');
        } catch (e) {
          this.number = '';
        }
      } catch (e) {
        this.countryCallingCode = '';
        this.number = '';
      }
      // calling onChange to fire validation
      this.onChange(this._number);
    }
  }

  get formattedNumber(): string {
    if (!this._formattedNumber) {
      return;
    }

    return new AsYouType(this._countryCode).input(this._formattedNumber);
  }

  // called when user types number
  set formattedNumber(formattedNumber: string) {
    if (formattedNumber !== this._formattedNumber) {
      const parsedNumber = PhoneInputComponent._parseNumber(formattedNumber, this._countryCode);
      this._formattedNumber = formattedNumber;
      if (parsedNumber.phone && isValidNumber(parsedNumber)) {
        this.number = formatNumber(parsedNumber, 'E.164');
      } else {
        this.number = '';
      }
      // calling onChange to fire validation
      this.onChange(this._number);
    }
  }

  get number(): any {
    return this._number;
  }

  set number(number: any) {
    if (number !== this._number) {
      this._number = number;
      this.onChange(number);
    }
  }

  // method that writes a new value from the form model into the view
  writeValue(number: any) {
    this._number = number;
    if (this._number) {
      const parsedNumber = PhoneInputComponent._parseNumber(this._number);
      if (parsedNumber.country) {
        this._countryCode = parsedNumber.country;
        this.countryCallingCode = getCountryCallingCode(this._countryCode);
        this._formattedNumber = formatNumber(parsedNumber, 'NATIONAL');
      }
    }
    this.onChange(number);
  }

  onChange = (value) => {
  }

  onTouched = () => {
  }

  // method that registers a handler that should be called when something in the view has changed
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  // method that registers a handler that should be called when a control receives a touch event
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  // listening for on blur number (set in phone-input.component.html)
  onBlurNumber() {
    this.onTouched();
  }

  registerOnValidatorChange(fn: () => void): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const number: string = control.value;
    const error: any = {
      phoneValidator: {
        valid: false
      }
    };
    if (!number) {
      if (
        // phone is required (e.g. reseller.phone)
        this.phoneRequired ||
        // phone is entered, but invalid
        (
          // NOT USED: this.phoneValidity &&
          this._formattedNumber &&
          (this._formattedNumber.length > 3)
        )
      ) {
        return error;
      } else {
        return null;
      }
    }

    const parsedNumber = PhoneInputComponent._parseNumber(number);
    if (parsedNumber.phone && isValidNumber(number)) {
      return null;
    }

    return {
      phoneValidator: {
        valid: false
      }
    };
  }
}
