import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import * as _ from 'lodash';
import * as dashjs from 'dashjs';
import { environment } from '../../../../../../environments/environment';
import { DashPlayerService } from '../../../../../services/dash-player/dash-player.service';

/* NOT USED:
enum Debug {
  LOG_LEVEL_NONE,
  LOG_LEVEL_FATAL,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_WARNING,
  LOG_LEVEL_INFO,
  LOG_LEVEL_DEBUG
}
enum Constants {
  LIVE_CATCHUP_MODE_LOLP = 'liveCatchupModeLoLP'
}
*/

@Component({
  selector: 'app-meeting-player',
  templateUrl: './meeting-player.component.html',
  styleUrls: ['./meeting-player.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MeetingPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('videoTarget') videoTarget: ElementRef;
  @ViewChild('audioTarget') audioTarget: ElementRef;
  // src string coming from parent http-audience component
  @Input() src: string;
  // bool to check if parent component joined
  @Input() parentJoined: boolean;
  @Input() langsFromParent: any;
  @Input() playerSettings: {};
  @Input() autoAdjust: boolean;
  @Input() autoResynch: boolean;
  @Output() liveLatencyStats: EventEmitter<any> = new EventEmitter();
  @Output() languageSelected: EventEmitter<any> = new EventEmitter();
  @Output() originalMuteChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() translationMuteChanged: EventEmitter<boolean> = new EventEmitter();
  //@Output() volumesChanged: EventEmitter<{}> = new EventEmitter();
  // dash.js player instance
  videoPlayer: dashjs.MediaPlayerClass;
  audioPlayer: dashjs.MediaPlayerClass;
  controlBar: any;
  languages: any;
  latencyStats: any = {};
  pollInterval: any;
  audioTracks: any;
  initialSettings: any = {};
  currentSettings: any = {};
  intervalToRetryPlaying: any;
  translationMuted = false;
  originalMuted = false;
  originalVol = 1;
  translationVol = 0;
  boolAutoAdjustment = false;
  boolAutoResynchAdjustment = false;
  // LOG LEVEL: 0=NONE, 1=FATAL, 2=ERROR, 3=WARNING, 4=INFO, 5=DEBUG
  debugLogLevel = 1; // Set 'debug.logLevel' to 1 (FATAL) for production
  embed = false; // To fix ERROR: Property 'embed' does not exist on type 'MeetingPlayerComponent'.
  fixAsynchTms = 0;
  curMinBuffer = -1;
  prevBuffer = -1;

  constructor() {
    // Set 'debug.logLevel' to 5 (DEBUG) for development
    if ((environment.name === 'development') || (environment.name === 'local')) {
      this.debugLogLevel = 5;
    }

    // dash.js player initial settings
    this.initialSettings = {
      debug: {
        logLevel: this.debugLogLevel // [3], range: 0 (NONE) to 5 (DEBUG)
      },
      streaming: {
        buffer: {
          stallThreshold: 0.05 // default: 0.3
        },
        lowLatencyEnabled: true, // default: false
        abr: {
          useDefaultABRRules: true, // default: true
          ABRStrategy: 'abrLoLP', // default: "abrDynamic"
          fetchThroughputCalculationMode: 'abrFetchThroughputCalculationMoofParsing' // ["abrFetchThroughputCalculationDownloadedData"]
        },
        delay: {
          liveDelay: 10 // Equivalent in seconds of setLiveDelayFragmentCount.
        },
        liveCatchup: {
          minDrift: 0.05, // default: 0.02, range: 0.0-0.5
          //maxDrift: 12, // default: 12, 0 => not used
          playbackRate: 0.5, // PREV: 0.1, default: 0.5, range: 0-0.5 (0-50%), PREV: 0.1
          latencyThreshold: 60, // PREV: 30, [2 * MAX live delay]
          playbackBufferMin: 1.667, // PREV: 6, default: NaN, 1/60*liveDelay, LoLP only
          mode: 'liveCatchupModeLoLP' // ["liveCatchupModeDefault"]
        }
      }
    };
  }

  ngOnInit() {
  }

  setAudioTrack(index) {
    console.log('setAudioTrack: audioTracks:', this.audioTracks);
    if (this.audioPlayer && this.videoPlayer) {
      this.audioTracks = this.audioPlayer.getTracksFor('audio');
      this.audioPlayer.setCurrentTrack(this.audioTracks[index]);
      this.videoPlayer.setCurrentTrack(this.audioTracks[0]);
    }
  }

  reloadSettings(settings) {
    this.resetPlayers(settings);
  }

  // checking for changes in the inputs to update the player state accordingly
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'parentJoined':
            if (changes.parentJoined.previousValue !== changes.parentJoined.currentValue) {
              this.initializePlayers(this.initialSettings);
              this.currentSettings = _.cloneDeep(this.initialSettings);
            }
            break;
          case 'playerSettings':
            if (this.videoPlayer && this.audioPlayer && changes && changes.playerSettings) {
              if (changes.playerSettings.currentValue.apply) {
                this.resetAndUpdateSettings(changes.playerSettings.currentValue);
                this.currentSettings = changes.playerSettings.currentValue;
                this.play();
              } else if (changes.playerSettings.currentValue.seek) {
                this.seekToLive();
              }
            }
            break;
          case 'langsFromParent':
            if (changes && changes.langsFromParent) {
              this.languages = changes.langsFromParent.currentValue;
              console.log('ngOnChanges: langsFromParent: languages:', this.languages);
            }
            break;
          case 'autoAdjust':
            if (changes && changes.autoAdjust) {
              this.boolAutoAdjustment = changes.autoAdjust.currentValue;
              console.log('ngOnChanges: autoAdjust: boolAutoAdjustment:', this.boolAutoAdjustment);
            }
            break;
          case 'autoResynch':
            if (changes && changes.autoResynch) {
              this.boolAutoResynchAdjustment = changes.autoResynch.currentValue;
              console.log('ngOnChanges: autoResynch: boolAutoResynchAdjustment:', this.boolAutoResynchAdjustment);
            }
            break;
        }
      }
    }
  }

  play() {
    if (this.videoPlayer && this.audioPlayer) {
      this.videoPlayer.play();
      this.audioPlayer.play();
    }
  }

  // AUTO ADJUSTMENT (EXPERIMENTAL)
  autoAdjustment() {
    if (!this.currentSettings) {
      this.currentSettings = _.cloneDeep(this.initialSettings);
    }

    let zDoIt = false;

    if (this.latencyStats && this.latencyStats.currentVideoPlayerVideoBuffer && this.latencyStats.currentVideoPlayerLiveLatency) {
      const newSettings = _.cloneDeep(this.currentSettings);
      let loLPPlaybackBufferMin = 1.667;

      // buffer and latency have positive values
      if ((this.latencyStats.currentVideoPlayerVideoBuffer > 0) && (this.latencyStats.currentVideoPlayerLiveLatency > 0)) {
        const setLatency = this.currentSettings.streaming.delay.liveDelay;
        const liveLatency = this.latencyStats.currentVideoPlayerLiveLatency;
        const averageLiveDelay = (setLatency + liveLatency + this.initialSettings.streaming.delay.liveDelay) / 3; // weighted: (6 * liveLatency + 3 * setLatency + this.initialSettings.streaming.delay.liveDelay) / 10;
        const liveBuffer = this.latencyStats.currentVideoPlayerVideoBuffer;

        // 'loLPPlaybackBufferMin' calculation
        // Note: it is used by 'LoLP', but also in 'Auto latency adjustment'
        loLPPlaybackBufferMin = Math.ceil(1000 * averageLiveDelay / 6) / 1000;
        if (loLPPlaybackBufferMin < 0.5) {
          loLPPlaybackBufferMin = 0.5; // minimum allowed value
        } else if (loLPPlaybackBufferMin > 2) {
          loLPPlaybackBufferMin = 2; // maximum allowed value
        }

        // 'curMinBuffer' calculation for 'Auto latency adjustment'
        if (this.prevBuffer < 0) {
          this.prevBuffer = liveBuffer;
          this.curMinBuffer = -1;
        } else {
          // there was an inversion, so the previous value was the previous minimum buffer
          if (liveBuffer > this.prevBuffer) {
            this.curMinBuffer = this.prevBuffer;
          }

          this.prevBuffer = liveBuffer;
        }

        // Calculating the wanted latency (integer)
        newSettings.streaming.delay.liveDelay = Math.round((liveLatency + setLatency + averageLiveDelay) / 3);
        // in decimals: newSettings.streaming.delay.liveDelay = Math.round(10 * (liveLatency + setLatency + averageLiveDelay) / 3) / 10;

        // Auto latency adjustment
        if ((liveBuffer > liveLatency) && (Math.floor(liveBuffer - 0.5 - liveLatency) > 1)) { // There is enough buffer
          // Notes: 0.5 is due to the 500 ms interval expressed in seconds
          //        we subtract 1 to 'liveDelay' for conservativeness
          newSettings.streaming.delay.liveDelay -= 1;
          //newSettings.streaming.liveCatchup.playbackRate = 0.1;
        } else if (this.curMinBuffer > loLPPlaybackBufferMin + 0.5) { // 'curMinBuffer' greater than 'loLPPlaybackBufferMin' + 0.5
          // Notes: 0.5 is due to the 500 ms interval expressed in seconds
          //        'loLPPlaybackBufferMin' is the minimum playback buffer for LoLP
          //        we keep 2/3 of it for conservativeness
          newSettings.streaming.delay.liveDelay -= Math.floor((this.curMinBuffer - 0.5 - loLPPlaybackBufferMin) * 2 / 3);
          //newSettings.streaming.liveCatchup.playbackRate = 0.1;
          this.curMinBuffer = -1;
          this.prevBuffer = -1;
        } else if (liveBuffer > 0.5 + liveLatency * 59 / 60) { // 'liveBuffer' greater than 59/60 of 'liveLatency'
          // Notes: the following will work only with 'liveLatency' expressed in decimals
          //        when enabled makes the 'liveLatency' value flipping too much.
          newSettings.streaming.delay.liveDelay -= Math.abs(Math.floor(liveBuffer - 0.5 - liveLatency * 59 / 60));
          //newSettings.streaming.liveCatchup.playbackRate = 0.1;
        } else if ((this.curMinBuffer > 0) && (this.curMinBuffer < loLPPlaybackBufferMin + 0.5 )) { // 'curMinBuffer' is less than 'loLPPlaybackBufferMin' + 0.5
          // Note: the following will work only with 'liveLatency' expressed in decimals
          newSettings.streaming.delay.liveDelay += Math.ceil(loLPPlaybackBufferMin + 0.5 - this.curMinBuffer);
          //newSettings.streaming.liveCatchup.playbackRate = 0.5;
          this.curMinBuffer = -1;
          this.prevBuffer = -1;
        }

        zDoIt = true;
      } else if (this.latencyStats.currentVideoPlayerLiveLatency > 0) { // buffer went below zero
        // 1 second increase
        newSettings.streaming.delay.liveDelay += 1;
        this.curMinBuffer = -1;
        this.prevBuffer = -1;

        // Video players is escaping upward
        // Note: this value (48) depends on 'window_size' and 'segment_duration' in the configuration
        //       the maximum allowed number is: 'window_size' x 'segment_duration'.
        if (this.latencyStats.currentVideoPlayerLiveLatency > 48) {
          newSettings.streaming.delay.liveDelay = 32;
          this.reloadSettings(newSettings);
        } else {
          zDoIt = true;
        }
      }
1
      if (zDoIt === true) {
        // LoLP only
        if (this.currentSettings.streaming.liveCatchup.mode === 'liveCatchupModeLoLP') {
          newSettings.streaming.liveCatchup.playbackBufferMin = loLPPlaybackBufferMin;
        }

        console.log('autoAdjustment: newSettings:', newSettings);
        this.currentSettings = _.cloneDeep(newSettings);
        this.updateSettings(newSettings);
      }
    }
  }

  onLanguageSelected(language) {
    this.languageSelected.emit(language);
  }

  toggleFullScreen() {
    if (this.videoTarget.nativeElement.webkitRequestFullScreen) {
      this.videoTarget.nativeElement.webkitRequestFullScreen();
    } else if (this.videoTarget.nativeElement.mozRequestFullScreen) {
      this.videoTarget.nativeElement.mozRequestFullScreen();
    }
  }

  toggleOriginalMute() {
    if (this.originalMuted) {
      this.originalMuted = false;
      this.videoPlayer.setMute(false);
      this.originalMuteChanged.next(false);
    } else {
      this.originalMuted = true;
      this.videoPlayer.setMute(true);
      this.originalMuteChanged.next(true);
    }
  }

  toggleTranslationMute() {
    if (this.translationMuted) {
      this.translationMuted = false;
      this.audioPlayer.setMute(false);
      this.translationMuteChanged.next(false);
    } else {
      this.translationMuted = true;
      this.audioPlayer.setMute(true);
      this.translationMuteChanged.next(true);
    }
  }

  setVolumes() {
    //console.log('setVolumes: Setting volumes for On Air');
    //if (this.translationMuted) {
    //  this.toggleTranslationMute();
    //}
    this.videoPlayer.setVolume(this.originalVol);
    this.audioPlayer.setVolume(this.translationVol);
    //this.volumesChanged.next({original: this.originalVol, translation: this.translationVol});
  }

  handleOriginalVol(newValue: number) {
    this.originalVol = newValue;
    console.log('handleOriginalVol: originalVol:', this.originalVol);
    if (((newValue === 0) && !this.originalMuted) || ((newValue > 0) && this.originalMuted)) {
      this.toggleOriginalMute();
    }

    this.setVolumes();
  }

  handleTranslationVol(newValue: number) {
    this.translationVol = newValue;
    console.log('handleTranslationVol: translationVol:', this.translationVol);
    if (((newValue === 0) && !this.translationMuted) || ((newValue > 0) && this.translationMuted)) {
      this.toggleTranslationMute();
    }

    this.setVolumes();
  }

  getCurrentOriginalVol() {
    return this.originalVol;
  }

  getCurrentTranslationVol() {
    return this.translationVol;
  }

  private seekToLive() {
    this.fixAsynchTms = 0;
    this.curMinBuffer = -1;
    this.prevBuffer = -1;
    let duration = this.videoPlayer.duration();
    console.log('seekToLive: duration:', (duration || 'unknown'));
    console.log('???DEBUG??? seekToLive: durationAsUTC:', (this.videoPlayer.durationAsUTC() || 'unknown'));
    duration += 10 - Math.round(this.videoPlayer.getCurrentLiveLatency() || 10);
    console.log('???DEBUG??? seekToLive: durationP:', (duration || 'unknown'));
    if (duration && duration > 0) {
      this.videoPlayer.seek(duration);
      this.audioPlayer.seek(duration);
    }
  }

  private stopPlayers() {
    if (this.videoPlayer) {
      this.videoPlayer.reset();
    }
    if (this.audioPlayer) {
      this.audioPlayer.reset();
    }
    clearInterval(this.pollInterval);
    this.fixAsynchTms = 0;
    this.curMinBuffer = -1;
    this.prevBuffer = -1;
  }

  private destroyPlayers() {
    this.stopPlayers();
    clearInterval(this.intervalToRetryPlaying);
  }

  private resetPlayers(settings) {
    console.warn('resetPlayers: lang:', DashPlayerService.lang);
    if (this.videoPlayer && this.audioPlayer) {
      if (!settings) {
        settings = this.videoPlayer.getSettings() || this.initialSettings;
      }
      this.stopPlayers();
      this.initializePlayers(settings);
      setTimeout(() => {
        this.play();
        this.onLanguageSelected(DashPlayerService.lang);
      }, 3000);
    }
  }

  private setRetryTimeout() {
    if (this.intervalToRetryPlaying) {
      return;
    }

    this.intervalToRetryPlaying = setInterval(() => {
      console.warn('intervalToRetryPlaying: Retrying to play stream');
      this.resetPlayers(null);
      //this.player.play();
      // TODO: if (flag)
      //this.seekToLive();
    }, 5000);
  }

  // Send the new video quality to the debugger (liveLatencyStats)
  private qualityChangeRendered(data) {
    if (data.mediaType === 'video') {
      console.log('qualityChangeRendered: data:', data);
      // Throws: STREAMING_NOT_INITIALIZED_ERROR if called before initializePlayback function
      const quality = this.videoPlayer.getBitrateInfoListFor('video')[data.newQuality];
      if (!quality) {
        return;
      }

      //console.warn('???DEBUG??? qualityChangeRendered: quality:', quality);
      if (this.latencyStats) {
        this.latencyStats.currentVideoPlayerVideoQuality = quality.width + '×' + quality.height + ', ' + (quality.bitrate / 1000) + ' kbps';
        this.latencyStats.currentAudioPlayerVideoQuality = quality.width + '×' + quality.height + ', ' + (quality.bitrate / 1000) + ' kbps';
        this.liveLatencyStats.emit(this.latencyStats);
      }
    }
  }

  // Adding event listeners to handle errors and retry playing video automatically
  private addEventListenersToPlayer(player: dashjs.MediaPlayerClass) {
    // PLAYBACK_PLAYING event
    player.on(dashjs.MediaPlayer.events.PLAYBACK_PLAYING, () => {
      // When the player is PLAYING we clear the interval to stop retrying
      clearInterval(this.intervalToRetryPlaying);
      this.intervalToRetryPlaying = null;

      // Attempt to synchronize the two players when they are not synchronized
      if (this.videoPlayer && this.audioPlayer) {
        const pDiff = Math.abs(this.videoPlayer.getCurrentLiveLatency() - this.audioPlayer.getCurrentLiveLatency());
        if (pDiff > 2) {
          if (pDiff > 4) this.resetPlayers(null);
          // Note: the conditional below MUST be removed... (it has been added temporarily to calculate the time for asynchrony of the videoPlayer)
          else if (this.boolAutoResynchAdjustment) this.seekToLive();
        }
      }
    });

    /* In case of [PLAYBACK_WAITING, PLAYBACK_ENDED, PLAYBACK_ERROR, ERROR] the player will attempt to
     * restart playing automatically every 5 seconds (check setRetryTimeout() declaration)
     */
    // PLAYBACK_WAITING event
    player.on(dashjs.MediaPlayer.events.PLAYBACK_WAITING, () => {
      this.setRetryTimeout();
    });

    // PLAYBACK_ENDED event
    player.on(dashjs.MediaPlayer.events.PLAYBACK_ENDED, () => {
      this.setRetryTimeout();
    });

    // PLAYBACK_ERROR event
    player.on(dashjs.MediaPlayer.events.PLAYBACK_ERROR, () => {
      this.setRetryTimeout();
    });

    // ERROR event
    player.on(dashjs.MediaPlayer.events.ERROR, (e) => {
      console.error('dashjs.MediaPlayer.events.ERROR:', e);
      this.setRetryTimeout();
    });

    // Restart playback in muted mode when auto playback was not allowed by the browser
    player.on(dashjs.MediaPlayer.events.PLAYBACK_NOT_ALLOWED, (data) => {
      console.warn('dashjs.MediaPlayer.events.PLAYBACK_NOT_ALLOWED: Playback did not start due to auto play restrictions. Muting audio and replaying');
      this.toggleOriginalMute();
      this.toggleTranslationMute();
      this.play();
    });

    // Get changes in video quality.
    player.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, (data) => {
      this.qualityChangeRendered(data);
    });
  }

  private updateSettings(settings) {
    if (!settings) {
      settings = this.videoPlayer.getSettings() || this.initialSettings;
    }
    this.videoPlayer.updateSettings(settings);
    this.audioPlayer.updateSettings(settings);
    this.initialSettings.streaming.delay.liveDelay = settings.streaming.delay.liveDelay;
  }

  private resetAndUpdateSettings(settings) {
    this.videoPlayer.resetSettings();
    this.audioPlayer.resetSettings();
    this.updateSettings(settings);
  }

  private initializePlayers(settings) {
    // Player initialization
    this.videoPlayer = dashjs.MediaPlayer().create();
    this.videoPlayer.initialize(this.videoTarget.nativeElement, this.src, false);

    this.audioPlayer = dashjs.MediaPlayer().create();
    this.audioPlayer.initialize(this.audioTarget.nativeElement, this.src, false);
    //this.player.on(dashjs.MediaPlayer.events.PLAYBACK_PLAYING, () => {
    //  this.videoTime.nativeElement.innerHTML = "LIVE";
    //});

    this.addEventListenersToPlayer(this.videoPlayer);
    this.addEventListenersToPlayer(this.audioPlayer);

    if (!settings) {
      settings = this.initialSettings;
    }
    this.updateSettings(settings);

    const dN = new Date();
    this.fixAsynchTms = 0;
    this.curMinBuffer = -1;
    this.prevBuffer = -1;

    this.pollInterval = setInterval(() => {
      const videoQuality = this.latencyStats.currentVideoPlayerVideoQuality || 'initial';
      const interpVideoQuality = this.latencyStats.currentAudioPlayerVideoQuality || 'initial';
      this.latencyStats = {
        currentVideoPlayerLiveLatency: this.videoPlayer.getCurrentLiveLatency(),
        currentAudioPlayerLiveLatency: this.audioPlayer.getCurrentLiveLatency(),
        currentVideoPlayerVideoBuffer: this.videoPlayer.getDashMetrics().getCurrentBufferLevel('video'),
        currentAudioPlayerVideoBuffer: this.audioPlayer.getDashMetrics().getCurrentBufferLevel('video'),
        currentVideoPlayerAudioBuffer: this.videoPlayer.getDashMetrics().getCurrentBufferLevel('audio'),
        currentAudioPlayerAudioBuffer: this.audioPlayer.getDashMetrics().getCurrentBufferLevel('audio'),
        currentVideoPlayerPlaybackRate: this.videoPlayer.getPlaybackRate(), // Math.round(this.videoPlayer.getPlaybackRate() * 100) / 100
        currentAudioPlayerPlaybackRate: this.audioPlayer.getPlaybackRate(), // Math.round(this.audioPlayer.getPlaybackRate() * 100) / 100
        currentVideoPlayerVideoQuality: videoQuality,
        currentAudioPlayerVideoQuality: interpVideoQuality,
        currentSetLatency: (this.currentSettings && this.currentSettings.streaming && this.currentSettings.streaming.delay && this.currentSettings.streaming.delay.liveDelay) ? this.currentSettings.streaming.delay.liveDelay : null,
        currentSetBufferMin: (this.currentSettings && this.currentSettings.streaming && this.currentSettings.streaming.liveCatchup.playbackBufferMin) ? this.currentSettings.streaming.liveCatchup.playbackBufferMin : null,
        dateStart: this.latencyStats && this.latencyStats.dateStart ? this.latencyStats.dateStart : dN,
        dateReStart: dN,
        dateNow: dN,
        durationAsUTC: this.videoPlayer.durationAsUTC(),
        duration: this.videoPlayer.duration(),
        timeAsUTC: this.videoPlayer.timeAsUTC(),
        time: this.videoPlayer.time()
      };
      this.liveLatencyStats.emit(this.latencyStats);

      if (this.boolAutoAdjustment) {
        this.autoAdjustment();
      }

      // Fix A/V asynchrony of the speaker source due to player playback rate changes
      if ((this.latencyStats.currentVideoPlayerPlaybackRate !== 1) && (this.fixAsynchTms === 0)) {
        this.fixAsynchTms = 1;
        // TODO: if (this.latencyStats.currentVideoPlayerPlaybackRate < 1) increase 'liveDelay'... ;
      }
      // Fixing has been started
      if (this.boolAutoResynchAdjustment && (this.fixAsynchTms > 0)) {
        this.fixAsynchTms += 1;
        // Seek to live after 300 tms = 150 seconds = 2 minutes and 30 seconds = 300 * 500 / 1000
        if (this.fixAsynchTms === 300) {
          this.seekToLive();
        }
      }
    }, 500);

    this.videoPlayer.setCurrentTrack(this.videoPlayer.getTracksFor('audio')[0]);
  }

  ngOnDestroy() {
    this.destroyPlayers();
  }
}
