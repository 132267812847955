import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs/index';
import { environment } from '../../../../environments/environment';
import { User } from '../../../models/User';
import { AuthService } from '../../../services/auth/auth.service';
import { IntercomService } from '../../../services/intercom/intercom.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  user: User;
  private subscription: Subscription;
  // Search and replace '2024-07-03T10:30:00' with the new value in all the files
  // Note: this needs to be done before each deployment to production.
  lastUpdated = '2024-07-03T10:30:00.000Z';
  today = new Date();
  websiteLang = localStorage.getItem('userWebsiteLanguage') || 'en';
  blogURL: string = 'https://blog.ablio.com/';
  twitterURL: string = 'https://twitter.com/abliodotcom';
  isEmbeddable = false;
  showCookiesGDPR = false;
  showPPLink = false;
  userNull = true;

  constructor(
    public sanitizer: DomSanitizer,
    private http: HttpClient,
    private router: Router,
    private authServ: AuthService,
    private intercomServ: IntercomService,
    private userServ: UserService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log('FooterComponent:NavigationStart url:', event.url);
        // isEmbeddable
        this.isEmbeddable = false;
        if (event.url) {
          if (
            event.url.startsWith('/audience') ||
            event.url.startsWith('/meeting')
          ) {
            this.isEmbeddable = true;
            return;
          }
        }

        // When user is null in NavigationStart
        if (this.user && (this.user.terms || this.user.ablioId)) {
            this.userNull = false;
        }

        // Check ac_cookiesGDPR on route changes
        if (
          !this.userNull ||
          (document.cookie.search('ac_cookiesGDPR') > -1) ||
          localStorage.getItem('ac_cookiesGDPR')
        ) {
          // Renew the cookie
          this.setCookiesGDPR();
        } else { // Show cookiesGDPRPanel
          this.showCookiesGDPR = true;
          this.showPPLink = true;
          // Hide the link to Cookies Policy when in 'privacy-policy'
          if (event.url && event.url.startsWith('/privacy-policy')) {
            this.showPPLink = false;
          }
        }
      }

      if (event instanceof NavigationEnd) {
        console.log('FooterComponent:NavigationEnd url:', event.url);

        // Update ac_cookiesGDPR when user is null in NavigationStart
        if (
          this.userNull &&
          this.user && (this.user.terms || this.user.ablioId)
        ) {
          // Renew the cookie for users
          this.setCookiesGDPR();
        }

        // Get the website last updated time
        this.getLastUpdated();
      }
    });
  }

  ngOnInit() {
    // Get the user object
    this.subscription = this.userServ.userChange.subscribe((user) => {
      this.user = user;
    });

    // Set the links in the footer depending on the website language
    // Note: on changing the website language there is a reload
    // and this part will be executed again
    if (this.websiteLang) {
      if (this.websiteLang === 'es') {
        this.twitterURL = 'https://twitter.com/abliodotcom?lang=es';
      } else if (this.websiteLang === 'it') {
        this.blogURL = 'https://ablio.it/blog/';
        this.twitterURL = 'https://twitter.com/ablio_italia?lang=it';
      }
    }
  }

  // Check if a forced page reload is needed
  siteReload() {
    // Get the stored last visit value or now [ISO date and time string]
    const lastVisit = localStorage.getItem('ac_userLastVisit') || new Date().toISOString();

    try {
      // Store the user last visit date and time in ISO-8601 format
      localStorage.setItem('ac_userLastVisit', new Date().toISOString());

      // User last visit is prior to the website last update
      if (this.lastUpdated > lastVisit) {
        // Set "no-cache" meta tags
        var meta = document.createElement('meta');
        meta.httpEquiv = 'cache-control';
        meta.content = 'no-cache, no-store, must-revalidate';
        document.getElementsByTagName('head')[0].appendChild(meta);
        meta = document.createElement('meta');
        meta.httpEquiv = 'pragma';
        meta.content = 'no-cache';
        document.getElementsByTagName('head')[0].appendChild(meta);
        meta = document.createElement('meta');
        meta.httpEquiv = 'expires';
        meta.content = '0';
        document.getElementsByTagName('head')[0].appendChild(meta);

        // Shutting down Intercom
        //this.intercomServ.shutdown();

        setTimeout(() => {
          console.log(
            '----------------------------------------------------------------\n' +
            'FooterComponent.siteReload RELOADING at', new Date().toISOString() + '\n' +
            '  Last updated:', (this.lastUpdated || 'unknown') + '\n' +
            '----------------------------------------------------------------'
          );

          // Force the reload of the current url
          location.reload(true);
        }, 1000);
      }
    } catch (err) {
      console.error('FooterComponent.localStorage ERROR!\nmessage:', err.message);
    }
  }

  // Get the website last modified time
  getLastUpdated() {
    // Skip in case of embeddable pages (audience, meeting, ...)
    if (this.isEmbeddable) return;

    this.http.get(`${environment.apiUrl}/wslmtime`, { observe: 'response',  responseType: 'json' }).subscribe((res) => {
      if (
        res && res.body && res.body['wslmtime'] &&
        (res.body['wslmtime'].length === 24) &&
        (res.body['wslmtime'] > this.lastUpdated)
      ) {
          this.lastUpdated = res.body['wslmtime'];
      }

      // Call 'siteReload' to check if a forced reload is needed
      this.siteReload();
    }, (err) => {
      console.error('FooterComponent.getLastUpdated.http.get ERROR!\nerr:', err);

      // Call 'siteReload' to check if a forced reload is needed
      this.siteReload();
    });
  }

  // Set or renew 'ac_cookiesGDPR'
  setCookiesGDPR() {
    // Skip in case of embeddable pages (audience, meeting)
    if (this.isEmbeddable) return;

    try {
      // Set 'ac_cookiesGDPR' localStorage to 'true'
      localStorage.setItem('ac_cookiesGDPR', 'true');
    } catch (err) {
      console.error('FooterController.setCookiesGDPR ERROR!\nmessage:', err.message);
    }

    // Set or update ac_cookiesGDPR in a cookie for 365 days from now
    document.cookie = 'ac_cookiesGDPR=true; expires=' + new Date(new Date().setDate(new Date().getDate() + 365)).toUTCString() + '; path=/; samesite=strict';

    this.showCookiesGDPR = false;
    this.showPPLink = false;
  }

  // Close the cookiesGDPRPanel
  public closeCookiesGDPRPanel() {
    this.setCookiesGDPR();
  }
}
