// Core imports
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';

import { MeetingPlayerComponent } from './meeting-player/meeting-player.component';

// db models imports
import { Event } from '../../../../models/Event';

// Application services imports
import { DashPlayerService } from '../../../../services/dash-player/dash-player.service';
import { EventService } from '../../../../services/event/event.service';
import { IntercomService } from '../../../../services/intercom/intercom.service';
import { KurentoService } from '../../../../services/kurento/kurento.service';

declare var console: any;

@Component({
  selector: 'app-http-audience',
  templateUrl: './http-audience.component.html',
  styleUrls: ['./http-audience.component.css']
})
// TODO: export class AudienceComponent implements OnInit, OnDestroy {src
export class HttpAudienceComponent implements OnInit, OnDestroy {
  @Input() eventIdFromParent: string;
  sub: any;
  subscriptions = new Subscription();

  currentLang: any = {};
  code: string;
  event: Event;
  eventId: string;
  eventIdChange: Subject<string> = new Subject<string>();
  eventStarted: boolean;
  eventStartedChanged: boolean;
  webPortal: boolean;
  eCode: string;
  eventCodeEntered: boolean;
  eventCodeError = '';
  joined: boolean;
  submitted: boolean;
  //speakerVolumeEnabled: boolean;
  //interpVolumeEnabled: boolean;
  speakerStreaming: boolean;
  showSettings = false;
  autoAdjust = false;
  autoResynch = false;
  originalMuted = false;
  translationMuted = false;
  controlBarVolumes: {original: number, translation: number};

  interpreterStreaming: { [key: string]: boolean; } = {};
  languageStatus: { [key: string]: boolean; } = {};
  dashSrc: any;
  dashSettings: any;
  dashLangs: any;
  livePlayerStats: any;

  firstChange: boolean = true;
  delayedOperation: boolean = false;
  debugMode: boolean;

  embed: boolean;
  innerWidth: any;
  innerHeight: any;

  today = new Date();

  @ViewChild(MeetingPlayerComponent)
  private playerComponent: MeetingPlayerComponent;

  constructor(
    //private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private eventServ: EventService,
    private intercomServ: IntercomService//,
    //private kurento: KurentoService
  ) {
    this.debugMode = false;

    // options passed to the dash player TODO add subscription to watch for changes
    this.dashSrc = '';

    this.dashSettings = {};

    this.subscriptions.add(KurentoService.eventStartedChange.subscribe((value) => {
      if (value && !this.eventStarted) {
        this.getEventById();
      }

      this.eventStarted = value;
      this.eventStartedChanged = true;
      // WIFIC-1000 : Cannot read properties of undefined or null (reading 'audience')
      this.webPortal = this.event && this.event.audience && this.event.audience.webPortal && this.event.audience.webPortal.available ? true : false;

      //this.speakerStreamingToggle();
    }));

    this.subscriptions.add(KurentoService.speakerActiveChange.subscribe((value) => {
      this.speakerStreaming = value;
      this.speakerStreamingToggle();
    }));

    //this.subscriptions.add(KurentoService.speakerStreamingChange.subscribe((value) => {
    //  this.speakerStreaming = value;
    //  this.speakerStreamingToggle();
    //}));

    this.subscriptions.add(KurentoService.interpreterStreamingChange.subscribe((data) => {
      console.log('HttpAudienceComponent.KurentoService.interpreterStreamingChange.subscribe: data.value:', data && data.value ? data.value : 'unknown');
      this.interpreterStreaming = data.value;
      //this.interpreterSwitch();

      const delayForSwitch = this.getDelayForSwitch();

      if (this.currentLang && !this.delayedOperation) {
        this.delayedOperation = true;
        this.checkTrack(this.currentLang, delayForSwitch, 'interpreterStreamingChange');
      } else {
        console.log('HttpAudienceComponent.KurentoService.interpreterStreamingChange.subscribe: Current language not set yet.');
      }
    }));

    this.subscriptions.add(this.eventIdChange.subscribe((value) => {
      console.log('HttpAudienceComponent.eventIdChange.subscribe: eventId:', (this.eventId || 'unknown'));

      // Check if the event id is valid
      if (this.eventId && this.eventId.match(/^[0-9A-Fa-f]{24}$/)) {
        KurentoService.eventId = this.eventId;

        // websocket connection initialization
        console.log('HttpAudienceComponent.eventIdChange.subscribe: KurentoService.eventIdd:', KurentoService.eventId);

        this.getEventById();

        // Event status polling
        KurentoService.checkEventStatus(false, 30000);
      }
    }));
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    KurentoService.startWsIfNotRunning();
    KurentoService.startWsAudio('', null);

    this.sub = this.route.queryParams.subscribe(params => {
      const eventId = params.event ? params.event : this.eventIdFromParent;
      if ((eventId && eventId.match(/^[0-9A-Fa-f]{24}$/)) || this.eventIdFromParent) {
        const embedParam = params.embed;
        if (embedParam) {
          // embed parameter found
          let embedVal;
          try {
            embedVal = JSON.parse(embedParam);
            if (typeof embedVal === 'boolean') {
              // embed parameter is a valid bool (true | false)
              this.embed = embedVal;
            } else {
              // embed parameter is valid JSON but not bool
              this.detectEmbed();
            }
          } catch (e) {
            // embed parameter is not valid JSON
            this.detectEmbed();
          }
        } else {
          // embed parameter not found
          this.detectEmbed();
        }

        if (this.embed) {
          setTimeout(() => {
            this.intercomServ.shutdown(false);
          }, 1000);
        }
        console.log('HttpAudienceComponent.ngOnInit: embed:', this.embed);

        if (eventId) {
          this.eventCodeEntered = true;

          setTimeout(() => {
            this.setEventId(eventId);
          }, 1000);
        }
      }
    });

    //this.audioChat = document.getElementById('audience-chat');

    // Hide top navbar, footer, and Intercom widget
    if (!this.eventIdFromParent) {
      this.hideThings();
    }

    // Show settings button in 'development'
    if ((environment.name === 'development') || (environment.name === 'local')) {
      this.showSettings = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    if ((this.innerWidth >= 320) && (this.innerHeight >= 280)) {
      this.embed = false;
    } else {
      this.embed = true;
    }
  }

  // TODO: Show top navbar, footer, and Intercom widget again
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
      for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
        document.getElementsByClassName('intercom-lightweight-app')[c].classList.remove('d-none');
      }
    }
    if (document.getElementById('intercom-container') !== null) {
      document.getElementById('intercom-container').classList.remove('d-none');
    }
  }

  detectEmbed() {
    try {
      // auto embed detection
      this.embed = (window.location !== window.parent.location) || (window.self !== window.top) || (window.frameElement !== null) || !((this.innerWidth >= 320) && (this.innerHeight >= 280));
    } catch (e) {
      // error detecting embed, defaulting to false
      this.embed = false;
    }
  }

  // Show form errors
  public showError(input): boolean {
    return (input.invalid && (input.touched || this.submitted));
  }

  // Show eventCodeErrors
  public showEventCodeError(error) {
    this.eventCodeError = error;
    setTimeout(() => {
      this.eventCodeError = '';
    }, 5000);
  }

  // set event id
  setEventId(eventId) {
    this.eventId = eventId;
    this.eventIdChange.next(this.eventId);
  }

  // Event validation
  validateEvent(navigate) {
    this.eventCodeError = '';
    // TODO: Check this.event.status, data[SECTION].sectionaStatus, this.event.date, this.event.eddDate, private/paid conferences, the maximum number of participants (ablioconference educational)
    this.eventCodeEntered = true;

    if (navigate) {
      this.router.navigate(['/meeting'], { queryParams: { event: this.eventId } });
    }
  }

  // get the event by event id
  getEventById() {
    this.eventServ.getEvent(this.eventId, (err, data) => {
      if (err) {
        console.error('HttpAudienceComponent.getEventById.eventServ.getEvent: ERROR! err:', err);
      }

      this.event = data;
      this.webPortal = this.event.audience.webPortal.available;

      // Show settings button in case of tests or 'testteam', '5c9e006dda33a10fe38fe6bc' (production) hardcoded
      if (this.event &&
        ((this.event.status === 'ablio-test') || (this.event.status === 'test') ||
         (this.event.owner && (this.event.owner.toString() === '5c9e006dda33a10fe38fe6bc'))
        )
      ) {
        this.showSettings = true;
      }

      if (this.eventStarted) {
        console.log('HttpAudienceComponent.getEventById.eventServ.getEvent: event.languages:', this.event && this.event.languages ? this.event.languages : 'unknown');
        this.currentLang = {
          desc: 'Original',
          languageCode: 'or',
          listeningToSpeaker: true,
          channel: 0
        };
        if (_.isEmpty(this.interpreterStreaming)) {
          this.event.languages.forEach(lang => {
            this.interpreterStreaming[this.eventId + '_' + lang.languageCode] = false;
          });
        }
        if (!this.dashLangs) {
          this.dashLangs = this.event.languages;
        }
      }

      this.validateEvent(false);
      this.getMpdStreamUrl();
      this.speakerStreamingToggle();
      this.join();
    });
  }

  // get the event by event code
  getEventByCode() {
    //console.log('HttpAudienceComponent.getEventByCode: eCode:', this.eCode);
    this.eventCodeError = '';

    this.eventServ.getByCode(this.eCode, (err, data) => {
      if (err) {
        console.error('HttpAudienceComponent.getEventByCode.getByCode: ERROR! err:', err);
        return this.showEventCodeError('error');
      }

      if (data && data._id) {
        this.setEventId(data._id.toString());
        this.eventId = data._id.toString();
        this.event = data;
        this.webPortal = this.event.audience.webPortal.available;

        this.currentLang = {
          desc: 'Original',
          languageCode: 'or',
          listeningToSpeaker: true,
          channel: 0
        };

        if (_.isEmpty(this.interpreterStreaming)) {
          this.event.languages.forEach(lang => {
            this.interpreterStreaming[this.eventId + '_' + lang.languageCode] = false;
          });
        }

        if (!this.dashLangs) {
          this.dashLangs = this.event.languages;
        }

        this.validateEvent(true);
        this.getMpdStreamUrl();
        this.speakerStreamingToggle();
        this.join();
      } else {
        return this.showEventCodeError('notfound');
      }
    });
  }

  execPlayerAction(action) {
    if (action && action.name && this.playerComponent) {
      switch (action.name) {
        case 'reload':
          this.playerComponent.reloadSettings(null);
          break;
        case 'selectLang':
          this.onInterpreterButtonClick(action.payload);
          break;
        case 'changeVolume':
          this.playerComponent.handleOriginalVol(action.payload);
          break;
        case 'changeTranslationVolume':
          this.playerComponent.handleTranslationVol(action.payload);
          break;
        case 'toggleOriginalMute':
          this.playerComponent.toggleOriginalMute();
          break;
        case 'toggleTranslationMute':
          this.playerComponent.toggleTranslationMute();
          break;
        case 'toggleFullScreen':
          this.playerComponent.toggleFullScreen();
          break;
        default:
          console.warn('HttpAudienceComponent.execPlayerAction: unhandled controlbar action: action.name:', action.name);
          break;
      }
    }
  }

  // send updated dash settings down to player component
  changeDashSettings(settings) {
    this.dashSettings = _.cloneDeep(settings);
  }

  onAutoAdjustmentChange(value) {
    this.autoAdjust = value;
  }

  onAutoResynchChange(value) {
    this.autoResynch = value;
  }

  // update live player stats
  updateLiveStats(stats) {
    this.livePlayerStats = stats;
  }

  setOriginalMuted(muted: boolean) {
    this.originalMuted = muted;
  }

  setTranslationMuted(muted: boolean) {
    this.translationMuted = muted;
  }

  // Submit the event code and handle the response
  public onSubmit(enterEventCodeForm) {
    this.eventCodeError = '';
    this.submitted = true;

    //console.log('HttpAudienceComponent.onSubmit: enterEventCodeForm:', enterEventCodeForm);
    // Form validation
    if (enterEventCodeForm.invalid) {
      return;
    }

    //console.log(HttpAudienceComponent.onSubmit: eCode:', this.eCode);
    this.getEventByCode();
  }

  // Hide top navbar, footer, and Intercom widget
  hideThings() {
    document.getElementById('topHeader').classList.add('d-none');
    document.getElementById('topNavbar').classList.add('d-none');
    document.getElementById('f').classList.add('d-none');
    // Shut down or hide Intercom widget
    let nRetries = 0;
    const hIWVar = setInterval(() => {
      nRetries++;
      let stopInt = false;
      if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
        for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
          document.getElementsByClassName('intercom-lightweight-app')[c].classList.add('d-none');
          stopInt = true;
        }
      }
      if (document.getElementById('intercom-container') !== null) {
        document.getElementById('intercom-container').classList.add('d-none');
        stopInt = true;
      }
      if (stopInt || (nRetries > 3)) {
        clearInterval(hIWVar);
      }
    }, 7500);
  }

  // debugMode toggle
  toggleDebugMenu() {
    this.debugMode = !this.debugMode;
  }

  // join button on single event meeting page
  join() {
    this.joined = true;
    KurentoService.httpViewerJoin();

    if (this.playerComponent) {
      setTimeout(() => {
        // WIFIC-1013 : Cannot read property 'play' of undefined
        if (this.playerComponent) this.playerComponent.play();
      }, 500);

      setTimeout(() => {
        //this.playerComponent.reloadSettings(null);
        this.checkTrack(DashPlayerService.lang, null, 'join');
      }, 1000);
    }

    //this.onInterpreterButtonClick(DashPlayerService.lang);
  }

  // on language selected
  onInterpreterButtonClick(language) {
    console.log('HttpAudienceComponent.onInterpreterButtonClic.onInterpreterButtonClick: language:', (language || 'unknown'));
    if (this.firstChange) {
      this.firstChange = false;
    }
    this.checkTrack(language, null, 'onInterpreterButtonClick');
  }

  // set player audio track from langTrack object
  // + handle listening to original track when interp not on air
  connectLanguageChannel(langTrack) {
    if (!langTrack || !this.playerComponent) {
      return;
    }

    console.log('HttpAudienceComponent.onInterpreterButtonClic.connectLanguageChannel: langTrack:', langTrack);
    //this.playerComponent.setAudioTrack(0);
    if (langTrack.listeningToSpeaker) {
      this.playerComponent.setAudioTrack(0);
    } else {
      this.playerComponent.setAudioTrack(langTrack.channel);
    }

    this.playerComponent.setVolumes();
    console.log('HttpAudienceComponent.onInterpreterButtonClic.connectLanguageChannel: SWITCHED LANGUAGE: currentLang:', this.currentLang);
  }

  // get the url of the mpd stream for this event
  getMpdStreamUrl() {
    const url = this.event.secureMediaHost + ':8080/live.mpd';
    //const url = 'https://5ff3389f1bf33d407d3df2d6.ablioaudience.com:8080/live.mpd'; // E-W3Ou
    //const url = 'https://60314c5326047bf5bc6670f5.ablioaudience.com:8080/live.mpd'; // F1xFj2
    //const url = 'https://livesim.dashif.org/livesim/chunkdur_1/ato_7/testpic4_8s/Manifest300.mpd'; // time
    //const url = 'https://media.axprod.net/TestVectors/v7-Clear/Manifest_MultiPeriod_1080p.mpd'; // multi track
    this.dashSrc = url;
  }

  // TO CHECK
  speakerStreamingToggle() {
    console.log('HttpAudienceComponent.speakerStreamingToggle.speakerStreaming:', this.speakerStreaming);
    if (!this.speakerStreaming) {
      this.joined = false;
    } else {
      this.joined = true;
      KurentoService.httpViewerJoin();
      setTimeout(() => {
        this.playerComponent.play();
      }, 500);
      if (!this.delayedOperation) {
        //this.playerComponent.reloadSettings(null);
        this.checkTrack(DashPlayerService.lang, null, 'speakerStreamingToggle');
      }

      //this.onInterpreterButtonClick(DashPlayerService.lang);
    }
  }

  // check if the selected language channel is on air and call connectLanguageChannel accordingly
  checkTrack(language, delay?, caller?) {
    console.log('HttpAudienceComponentcheckTrack: delayedOperation:', this.delayedOperation + '\ndelay:', delay + '\ncaller:', caller + '\ncurrentLang (old):', JSON.stringify(this.currentLang) + '\nlanguage (new): ' + JSON.stringify(language) + '\ntime: ' + new Date().toLocaleString());
    const interpreterCode = this.eventId + '_' + language.languageCode;

    setTimeout(() => {
      console.log('HttpAudienceComponent.checkTrack.setTimeout: language:', language);
      if (!this.currentLang || this.currentLang && (this.currentLang.languageCode !== language.languageCode)) {
        const tempLang = {
          desc: language.desc,
          languageCode: language.languageCode,
          channel: language.channel,
          listeningToSpeaker: true
        };
        this.currentLang = tempLang;
      }

      if ((language.languageCode !== 'or') && !this.interpreterStreaming[interpreterCode] && !this.currentLang.listeningToSpeaker) {
        // Listen to original speaker audio/video
        this.currentLang.listeningToSpeaker = true;
      } else if ((language.languageCode !== 'or') && this.interpreterStreaming[interpreterCode] && this.currentLang.listeningToSpeaker) {
        this.currentLang.listeningToSpeaker = false;
      }

      DashPlayerService.lang = this.currentLang;
      console.log('HttpAudienceComponentcheckTrack.setTimeout: DashPlayerService.lang:', DashPlayerService.lang);
      this.connectLanguageChannel(this.currentLang);

      if (delay && this.delayedOperation) {
        this.delayedOperation = false;
      }
    }, delay ? delay : 0);
  }

  getDelayForSwitch() {
    let delayForSwitch = 10;
    if (this.livePlayerStats && this.livePlayerStats.currentVideoPlayerLiveLatency && !this.firstChange) {
      delayForSwitch = 5000 + Math.round(this.livePlayerStats.currentVideoPlayerLiveLatency * 1000);
    };

    return delayForSwitch;
  }
}
