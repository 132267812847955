import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlashMessage, FlashMessageOptions } from '../../models/FlashMessage';

/**
 * Service used to show flash messages.
 *
 * Default options for all methods:
 * showCloseBtn: false (if true: will show up a clickable x button at the right to close the message)
 * closeOnClick: false (if true: message will be closed if the user clicks anywhere within the message alert)
 * grayOut: false (if true: will gray out the background when showing the message)
 * timeout: 5000 (message will stop showing after timeout ms time)
 *
 * Default options for error method:
 * cssClass: 'flash-messages-danger'
 *
 * Default options for info method:
 * cssClass: 'flash-messages-info'
 *
 * Default options for success method:
 * cssClass: 'flash-messages-success'
 *
 * Default options for warning method:
 * cssClass: 'flash-messages-warning'
 *
 * Example with all options enabled:
 * this.flashMessagesServ.success('Success message', { showCloseBtn: true, closeOnClick: true, grayOut: true, timeout: 3000 });
 *
 */

@Injectable({
  providedIn: 'root'
})
export class FlashMessagesService {
  messagesChange: Subject<FlashMessage[]> = new Subject<FlashMessage[]>();
  grayOutChange: Subject<boolean> = new Subject<boolean>();
  private readonly timeout = 5000; // 5 seconds
  private messages: FlashMessage[] = [];
  private grayOut = false;

  constructor() {
  }

  closeMessage(message: FlashMessage) {
    this.messages = this.messages.filter(m => m !== message);
    clearTimeout(message.timeoutId);
    this.messagesChange.next(this.messages);

    if (!this.messages.some(m => m.grayOut)) {
      this.setGrayOut(false);
    }
  }

  error(message: string, options: FlashMessageOptions = {}) {
    if (!options.cssClass) {
      options.cssClass = 'flash-messages-danger';
    }

    options = this.processOptions(options);
    this.showMessage(message, options);
  }

  info(message: string, options: FlashMessageOptions = {}) {
    if (!options.cssClass) {
      options.cssClass = 'flash-messages-info';
    }

    options = this.processOptions(options);
    this.showMessage(message, options);
  }

  success(message: string, options: FlashMessageOptions = {}) {
    if (!options.cssClass) {
      options.cssClass = 'flash-messages-success';
    }

    options = this.processOptions(options);
    this.showMessage(message, options);
  }

  warning(message: string, options: FlashMessageOptions = {}) {
    if (!options.cssClass) {
      options.cssClass = 'flash-messages-warning';
    }

    options = this.processOptions(options);
    this.showMessage(message, options);
  }

  private showMessage(text: string, options: FlashMessageOptions = {}) {
    const flashMessage: FlashMessage = {
      text: text,
      cssClass: options.cssClass,
      showCloseBtn: options.showCloseBtn,
      closeOnClick: options.closeOnClick,
      grayOut: options.grayOut,
      timeoutId: setTimeout(() => {
        this.closeMessage(flashMessage);
      }, options.timeout)
    };

    if (flashMessage.grayOut) {
      this.setGrayOut(true);
    }

    this.messages.push(flashMessage);
    this.messagesChange.next(this.messages);
  }

  private processOptions(options: FlashMessageOptions = {}): any {
    if (!options.timeout) {
      options.timeout = this.timeout;
    }

    return options;
  }

  private setGrayOut(grayOut: boolean) {
    this.grayOut = grayOut;
    this.grayOutChange.next(this.grayOut);
  }
}
