// Core imports
// TODO: import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
// TODO: import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { WebRtcPeer } from '../../../../libs/WebRtcPeer';
// db models imports
import { Event } from '../../../../models/Event';
// Application services imports
import { EventService } from '../../../../services/event/event.service';
import { KurentoService } from '../../../../services/kurento/kurento.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';
import { LanguagesService } from '../../../../services/util/languages.service';

declare var console: any;

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
// TODO: export class AudienceComponent implements OnInit, OnDestroy {
export class AudienceComponent implements OnInit, OnDestroy {
  @Input() eventIdFromParent: string;
  sub: any;
  subscriptions = new Subscription();

  webRtcPeerReceive: WebRtcPeer;

  lang: string;
  code: string;
  event: Event;
  eventId: string;
  eventIdChange: Subject<string> = new Subject<string>();
  eventStarted: boolean;
  eventStartedChanged: boolean;
  eCode: string;
  eventCodeEntered: boolean;
  eventCodeError = '';
  submitted: boolean;
  audienceVolume: any;
  audioComponent: any;

  speakerStreaming: any;
  interpreterStreaming: { [key: string]: boolean; } = {};

  receiverEnabled = true;
  stopReceiverEnabled = false;
  speakerConstraints: Object;
  // audience should receive only audio
  audienceMediaConstraints: {
    audio: true,
    video: false
  }

  //audioChat: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventServ: EventService,
    private kurento: KurentoService,
    private flashMessagesServ: FlashMessagesService,
    //private sanitizer: DomSanitizer,
    public langServ: LanguagesService
  ) {
    //this.interpreterStreaming = KurentoService.interpreterStreaming;
    //this.interpreterStreamingToggle();

    this.subscriptions.add(KurentoService.eventStartedChange.subscribe((value) => {
      this.eventStarted = value;
      this.eventStartedChanged = true;
      //this.speakerStreamingToggle();
    }));

    // Used only for managers to stop any audience playing after restarting conference
    this.subscriptions.add(KurentoService.eventRestartedChange.subscribe((value) => {
      this.eventStarted = value;
      this.eventStartedChanged = true;
      if (this.isStopReceiverEnabled()) {
        this.stop();
      }
    }));

    this.subscriptions.add(KurentoService.interpreterStreamingChange.subscribe((data) => {
      this.interpreterStreaming = data.value;
      this.interpreterStreamingToggle();
    }));


    this.subscriptions.add(KurentoService.speakerStreamingChange.subscribe((value) => {
      this.speakerStreaming = value;
      this.speakerStreamingToggle();
    }));

    this.subscriptions.add(KurentoService.speakerConstraintsChange.subscribe((value) => {
      this.speakerConstraints = value;
      this.speakerConstraintsToggle();
    }));

    this.subscriptions.add(KurentoService.errorProcessingAnswerOnAudienceResponse.subscribe(() => {
      this.retryConnectInterpreter();
    }));

    this.subscriptions.add(this.eventIdChange.subscribe((value) => {
      console.log('AudienceComponent.eventIdChange eventId:', this.eventId);
      KurentoService.eventId = this.eventId;

      // websocket connection initialization
      console.log('AudienceComponent.eventIdChange KurentoService.eventId:', KurentoService.eventId);

      this.getEventById();

      // Event status polling
      KurentoService.checkEventStatus(false, 30000);
    }));
    //KurentoService.startWsAudio('it_IT');
  }

  ngOnInit() {
    KurentoService.startWsIfNotRunning();
    KurentoService.startWsAudio('', null);

    this.sub = this.route.queryParams.subscribe(params => {
      const eventId = (params['event']) ? (params['event']) : this.eventIdFromParent;
      // eventId exists and is valid
      if (eventId && eventId.match(/^[0-9A-Fa-f]{24}$/)) {
        this.eventCodeEntered = true;

        setTimeout(() => {
          this.setEventId(eventId);
        }, 1000);
      } else {
        console.warn('AudienceComponent.queryParams eventId:', (eventId || 'unknown'), 'is inavlid');
        this.eventCodeEntered = false;
        return this.showEventCodeError('notfound');
      }
    });

    //this.audioChat = document.getElementById('audience-chat');

    // Hide top navbar, footer, and Intercom widget
    if (!this.eventIdFromParent) {
      this.hideThings();
    }
  }

  // Show form errors
  public showError(input): boolean {
    return (input.invalid && (input.touched || this.submitted));
  }

  // Show eventCodeErrors
  public showEventCodeError(error) {
    this.eventCodeError = error;
    setTimeout(() => {
      this.eventCodeError = '';
    }, 5000);
  }

  setEventId(eventId) {
    this.eventId = eventId;
    this.eventIdChange.next(this.eventId);
  }

  // Event validation
  validateEvent(navigate) {
    this.eventCodeError = '';

    // TODO: Check this.event.status

    // TODO: Check data[SECTION].sectionaStatus

    // TODO: Check this.event.date

    // TODO: Check this.event.eddDate

    // TODO: Check for private/paid conferences

    // TODO: Check the maximum number of participants (ablioconference educational)

    this.eventCodeEntered = true;

    if (navigate) {
      this.router.navigate(['/audience'], { queryParams: { event: this.eventId } });
    }
  }

  // get the event by event id
  getEventById() {
    this.eventServ.getEvent(this.eventId, (err, data) => {
      if (err) {
        console.error('AudienceComponent.getEvent ERROR: err:', err);
        //return this.flashMessagesServ.show('Error loading event: ' + err.error.message, {cssClass: 'flash-messages-danger'});
      }

      this.event = data;

      if (this.eventStarted) {
        console.log('AudienceComponent.getEvent.eventStarted event.languages:', this.event.languages);
      }

      //this.flashMessagesServ.show('Success loading event', {cssClass: 'flash-messages-success'});
      this.validateEvent(false);
    });
  }

  // get the event by event code
  getEventByCode() {
    //console.log(this.eCode);
    this.eventCodeError = '';

    this.eventServ.getByCode(this.eCode, (err, data) => {
      if (err) {
        console.error('AudienceComponent.getEventByCode.getByCode ERROR: err:', err);
        return this.showEventCodeError('error');
      }

      if (data && data._id) {
        //this.setEventId(data._id.toString());
        this.eventId = data._id.toString();
        this.event = data;
        this.validateEvent(true);
      } else {
        return this.showEventCodeError('notfound');
      }
    });
  }

  // Submit the event code and handle the response
  public onSubmit(enterEventCodeForm) {
    this.eventCodeError = '';
    this.submitted = true;

    //console.log('enterEventCodeForm', enterEventCodeForm);
    // Form validation
    if (enterEventCodeForm.invalid) {
      return;
    }

    //console.log(this.eCode);
    this.getEventByCode();
  }

  // Hide top header and navbar, footer, and Intercom widget
  hideThings() {
    document.getElementById('topHeader').classList.add('d-none');
    document.getElementById('topNavbar').classList.add('d-none');
    document.getElementById('f').classList.add('d-none');
    // TODO: Shut down or hide Intercom widget
    let nRetries = 0;
    const hIWI = setInterval(() => {
      nRetries++;
      let stopInt = false;
      if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
        for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
          document.getElementsByClassName('intercom-lightweight-app')[c].classList.add('d-none');
          stopInt = true;
        }
      }
      if (document.getElementById('intercom-container') !== null) {
        document.getElementById('intercom-container').classList.add('d-none');
        stopInt = true;
      }
      if (stopInt || (nRetries > 3)) {
        clearInterval(hIWI);
      }
    }, 7500);
  }

  private disposeWebRtcPeer() {
    KurentoService.disposeWebRtcPeer(this.webRtcPeerReceive);
  }

  connectInterpreter(lang: string) {
    console.log('AudienceComponent.connectInterpreter: Connecting to interpreter of', lang, 'language');

    // Loads html elements
    this.audienceVolume = document.getElementById('volume-audience');
    this.audioComponent = document.getElementById('audio-interpreter');

    // Sets the language to connect in the service
    KurentoService.lang = lang;
    this.lang = lang;
    //const audioComponent = document.getElementById('audio-interpreter');
    this.volumeChange();

    const options = {
      mediaConstraints: this.audienceMediaConstraints,
      remoteVideo: this.audioComponent,
      onicecandidate: KurentoService.onIceCandidateAudio
    };

    this.disposeWebRtcPeer();
    KurentoService.webRtcPeerAudience = KurentoService.webRtcPeerInterpRcv = this.webRtcPeerReceive = WebRtcPeer.Recvonly(options, (error) => {
      if (error) {
        throw new Error(error);
      }
      this.webRtcPeerReceive.generateOffer(KurentoService.onOfferViewerAudioAsAudience);
    });
  }

  retryConnectInterpreter() {
    this.connectInterpreter(this.lang);
  }

  volumeChange() {
    //var audioComponent = document.getElementById('audio-interpreter');
    if (this.audienceVolume && this.audienceVolume.value) this.audioComponent.volume = this.audienceVolume.value;
    //else...
  }

  /*
  viewer() {
    this.receiverEnabled = false;
    this.stopReceiverEnabled = false;

    //KurentoService.startWsAudio('it_IT');

    if (!KurentoService.webRtcPeer) {
      const options = {
        remoteVideo: this.audioChat,
        onicecandidate: KurentoService.onIceCandidateAudio
      };

      KurentoService.webRtcPeer = WebRtcPeer.WebRtcPeerRecvonly(options, function (error) {
        if (error) {
          throw new Error(error);
        }

        this.generateOffer(KurentoService.onOfferViewerAudio);
        //console.log('AudienceComponent - Is interpreter streaming? ' + KurentoService.interpreterStreaming);
      });

      //setTimeout(() => {
      //  console.log('AudienceComponent - Is interpreter streaming? ' + KurentoService.interpreterStreaming);
      //  if (KurentoService.interpreterStreaming) {
      //    this.receiverEnabled = false;
      //    this.stopReceiverEnabled = true;
      //  } else {
      //    this.receiverEnabled = true;
      //    this.stopReceiverEnabled = false;
      //    this.stop();
      //  }
      //}, 2500);
    }
  }
  */

  private getCode() {
    return this.eventId + '_' + KurentoService.lang;
  }

  stop() {
    if (this.interpreterStreaming[this.getCode()]) {
      // stop listening to interpreter
      KurentoService.onStopAudio(false);
    } else {
      // stop listening to speaker
      KurentoService.onStop();
    }

    if (KurentoService.webRtcPeerAudience) {
      this.dispose();
      this.receiverEnabled = true;
      this.stopReceiverEnabled = false;
      this.speakerStreaming = false;
    }
  }

  /*
  interpreterStreamingToggle() {
    console.log('AudienceComponent - Is interpreter streaming? ' + this.interpreterStreaming[this.code]);

    this.receiverEnabled = false;

    if (this.interpreterStreaming[this.code]) {
      this.stopReceiverEnabled = true;
      //this.flashMessagesServ.warning('WARNING: Interpreter has started streaming. Click on receive audio to listen the translation');
    } else {
      this.stopReceiverEnabled = false;
      this.stop();
      this.flashMessagesServ.warning('WARNING: Cannot receive '+this.code+' interpreter audio. Please try later…');
    }
  }
  */

  speakerConstraintsToggle() {
    // If no constraints or is the speaker (em dashboard) the one trying to listen to himself
    if (!this.speakerConstraints || this.eventIdFromParent) {
      // Speaker unavailable message on page
      return this.speakerUnavailableMsg(this.speakerStreaming);
    }
    // Otherwise listen to speaker streaming
    const options = {
      mediaConstraints: this.audienceMediaConstraints,
      remoteVideo: this.audioComponent,
      onicecandidate: KurentoService.onIceCandidate
    };

    console.log('AudienceComponent.speakerConstraintsToggle: options:', options);

    //this.disposeWebRtcPeerReceiveSpeaker();
    KurentoService.webRtcPeerAudience = KurentoService.webRtcPeerInterpRcv = WebRtcPeer.Recvonly(options, function (error) {
      if (error) {
        throw new Error(error);
      }

      this.generateOffer(KurentoService.onOfferViewer);
    });
  }

  speakerStreamingToggle() {
    console.log('AudienceComponent.speakerStreamingToggle: speakerStreaming:', this.speakerStreaming);
    const code = this.getCode();

    if (this.interpreterStreaming[code]) {
      // if audience is listening to interpreter and speaker stops program: do nothing.
      return;
    }

    // Enable channel buttons / disable stop tutton
    if(!this.speakerStreaming){
      this.receiverEnabled = true;
      this.stopReceiverEnabled = false;

      // If interpreter is not streaming: dispose WebRTC peer
      if (!this.interpreterStreaming[code] && KurentoService.webRtcPeerAudience) {
        this.dispose();
      }
    } else {
      this.receiverEnabled = false;
      this.stopReceiverEnabled = true;
      this.interpreterStreaming[code] = false;
    }
  }

  interpreterStreamingToggle() {
    const code = this.getCode();
    console.log('AudienceComponent.interpreterStreamingToggle: interpreterStreaming:' + this.interpreterStreaming[code]);


    if (!this.interpreterStreaming[code]) {

      if (!this.speakerStreaming) {
        this.receiverEnabled = true;
        this.stopReceiverEnabled = false;

        //Speaker unavailable message on page
        this.flashMessagesServ.warning('Cannot receive the selected interpreter audio. Switching to original language…');

        // Listen to original speaker audio/video
        KurentoService.onViewerConstraints(true);
      }
    } else {
      this.receiverEnabled = false;
      this.stopReceiverEnabled = true;

      // If was connected to speaker reconnects to interpreter
      if (this.speakerStreaming) {
        console.log('AudienceComponent.interpreterStreamingToggle: speaker is already streaming…')
        this.connectInterpreter(this.lang);
        this.speakerStreaming = false;
      }
    }
  }

  isReceiverEnabled() {
    return this.receiverEnabled;
  }

  isStopReceiverEnabled() {
    return this.stopReceiverEnabled;
  }

  speakerUnavailableMsg(check: any) {
    if (!check) {
      //this.viewerEnabled = true;
      //this.stop();
      this.flashMessagesServ.warning('Cannot receive speaker original audio. Please try later…');
    }
  }

  dispose() {
    this.lang = null;
    this.disposeWebRtcPeer();
    KurentoService.disposeWebRtcPeer(KurentoService.webRtcPeerAudience);
  }

  /*
  @HostListener('window:beforeunload')
  ngOnUnload() {
    KurentoService.wsAudio.close();
  }
  */

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.stop();
  }

  ngOnDestroy() {
    if (!this.eventIdFromParent) {
      // Show top header and navbar, footer, and Intercom widget again
      document.getElementById('topHeader').classList.remove('d-none');
      document.getElementById('topNavbar').classList.remove('d-none');
      document.getElementById('f').classList.remove('d-none');
      // TODO: Start up or show Intercom widget again
      if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
        for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
          document.getElementsByClassName('intercom-lightweight-app')[c].classList.remove('d-none');
        }
      }
      if (document.getElementById('intercom-container') !== null) {
        document.getElementById('intercom-container').classList.remove('d-none');
      }
    }

    this.subscriptions.unsubscribe();
  }
}
