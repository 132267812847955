import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserGroup } from '../../../../models/User';
import { UserService } from '../../../../services/user/user.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild('addToBalanceModal') addToBalanceModal;
  routerURL: string;
  user: User;
  userType: string;
  isRsllr: boolean;
  requiredService: boolean = false;
  requiredSize: boolean = false;
  language1: string;
  language2: string;

  constructor(
    private router: Router,
    private userServ: UserService,
    private flashMessagesServ: FlashMessagesService
  ) {
  }

  ngOnInit() {
    this.routerURL = this.router.url; // ['/user/profile', '/dashboard/account']
    this.user = Object.assign({}, this.userServ.getUser());

    if (this.user.groups) {
      const uType: any = this.user.groups;
      if (uType.indexOf('admin') > -1) {
        this.userType = 'Administrator';
      } else if (uType.indexOf('operator') > -1) {
        this.userType = 'Operator';
      } else if (uType.indexOf('reseller') > -1) {
        this.onAreaChanged(this.user.reseller.areas);
        this.userType = 'Reseller';
        this.isRsllr = true;
      } else if (uType.indexOf('resellr-candidate') > -1) {
        this.onAreaChanged(this.user.reseller.areas);
        this.userType = 'Reseller Candidate';
        this.isRsllr = true;
      } else if (uType.indexOf('owner') > -1) {
        this.userType = 'Owner';
      } else if (uType.indexOf('event-master') > -1) {
        this.userType = 'Event Master';
      } else if (uType.indexOf('event-manager') > -1) {
        this.userType = 'Event Manager';
      } else if (uType.indexOf('interpreter') > -1) {
        this.userType = 'Interpreter';
        this.language1 = this.user.interpreter.languages[0] || 'en_US';
        this.language2 = this.user.interpreter.languages[1] || 'es_ES';
      }
    }

    if (!this.user.language) this.user.language = 'en_US';
    if (!this.user.websiteLanguage) this.user.websiteLanguage = 'en';
  }

  showError(input): boolean {
    return (input.invalid && input.touched);
  }

  onServicesChanged() {
    this.requiredService = false;
    if (
      !this.user ||
      !this.user.reseller ||
      !this.user.reseller.services || (
        !this.user.reseller.services.techonsite &&
        !this.user.reseller.services.techweb &&
        !this.user.reseller.services.audiovideo &&
        !this.user.reseller.services.lighting &&
        !this.user.reseller.services.translation &&
        !this.user.reseller.services.remotebooths &&
        !this.user.reseller.services.staging &&
        !this.user.reseller.services.management &&
        !this.user.reseller.services.hostessing
      )
    ) {
      this.requiredService = true;
    }
  }

  onSizesChanged() {
    this.requiredSize = false;
    if (
      !this.user ||
      !this.user.reseller ||
      !this.user.reseller.sizes || (
        !this.user.reseller.sizes.small &&
        !this.user.reseller.sizes.medium &&
        !this.user.reseller.sizes.large &&
        !this.user.reseller.sizes.xlarge
      )
    ) {
      this.requiredSize = true;
    }
  }

  onAreaChanged(area) {
    // TODO: save the selected area in the user model
    this.user.reseller.areas = area;
  }

  onSubmit(profileForm) {
    if (profileForm.invalid) return;

    if (this.isRsllr) {
      if (
        !this.user ||
        !this.user.reseller ||
        !this.user.reseller.services || (
          !this.user.reseller.services.techonsite &&
          !this.user.reseller.services.techweb &&
          !this.user.reseller.services.audiovideo &&
          !this.user.reseller.services.lighting &&
          !this.user.reseller.services.translation &&
          !this.user.reseller.services.remotebooths &&
          !this.user.reseller.services.staging &&
          !this.user.reseller.services.management &&
          !this.user.reseller.services.hostessing
        )
      ) {
        return this.requiredService = true;
      }

      if (
        !this.user ||
        !this.user.reseller ||
        !this.user.reseller.sizes || (
          !this.user.reseller.sizes.small &&
          !this.user.reseller.sizes.medium &&
          !this.user.reseller.sizes.large &&
          !this.user.reseller.sizes.xlarge
        )
      ) {
        return this.requiredSize = true;
      }
    }

    //const grps: any = ['resellr-candidate', 'owner', 'event-master', 'event-manager'];
    //if ((this.userType === 'Owner') && this.isRsllr) this.user.groups = grps;
    if ((this.userType === 'Owner') && this.isRsllr) {
      this.user.groups = [UserGroup.RESELLER_CANDIDATE, UserGroup.OWNER, UserGroup.EVENT_MASTER, UserGroup.EVENT_MANAGER];
    }

    // status: ['newlycreated', 'invited', 'active', 'sleeping', 'unpaid-billing', 'inreview', 'suspended', 'blocked', 'abusive']
    if (['newlycreated', 'invited', 'sleeping'].indexOf(this.user.status) > -1) {
      this.user.status = 'active';
    }

    this.userServ.update(this.user, (err, data) => {
      if (err) {
        console.log(err);
        return this.flashMessagesServ.error('Error saving user: ' + err.error.message);
      }

      if ((this.userType === 'Owner') && this.isRsllr) {
        this.userType = 'Reseller Candidate';
      }

      this.flashMessagesServ.success('Success saving user');
    });
  }

  applyForResellersProgram() {
    if (!this.isRsllr) {
      this.user.reseller = {
        company: '',
        phone: '',
        translationsByYear: 0,
        website: '',
        services: {},
        sizes: {},
        areas: { worldwide: true },
        notes: ''
      };
      this.isRsllr = true;
    } else {
      this.isRsllr = false;
      delete this.user.reseller;
    }
  }

  afterAddToBalance() {
    //this.user = Object.assign({}, this.userServ.getUser());
    setTimeout (() => {
      location.reload(true);
    }, 5000);
  }

  showAddToBalanceModal() {
    this.addToBalanceModal.show();
  }
}
