import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { User } from '../../models/User';
import { KurentoService } from '../kurento/kurento.service';

@Injectable()
export class UserService {
  userChange: Subject<User> = new BehaviorSubject<User>(null);
  private user: User;

  constructor(private http: HttpClient) {
  }

  public getUser(): User {
    return this.user;
  }

  public removeUser() {
    this.user = null;
    KurentoService.isManager = false;
    this.userChange.next(this.user);
  }

  checkLanguage(user: User) {
    if (
      user && user.websiteLanguage &&
      localStorage.getItem('userWebsiteLanguage') &&
      (user.websiteLanguage !== localStorage.getItem('userWebsiteLanguage'))
    ) {
      try {
        localStorage.setItem('userWebsiteLanguage', user.websiteLanguage);
        console.log('UserService.checkLanguage locale set to:', user.websiteLanguage);
        location.reload(true);
      } catch (err) {
        console.error('UserService.checkLanguage ERROR:', err.message);
      }
    }
  }

  public getInterpreterFromTeam(id) {
    if (!this.user || !this.user.team || !this.user.team.interpreters || !id) {
      return null;
    }

    const interpreters = this.user.team.interpreters;
    for (let i = 0; i < interpreters.length; i++) {
      if (id === interpreters[i]._id) {
        return interpreters[i];
      }
    }
  }

  public getInterpreterNameFromTeam(id) {
    if (!this.user || !this.user.team || !this.user.team.interpreters || !id) {
      return null;
    }

    const interpreters = this.user.team.interpreters;
    for (let i = 0; i < interpreters.length; i++) {
      if (id === interpreters[i]._id) {
        return interpreters[i].firstName + ' ' + interpreters[i].lastName;
      }
    }
  }

  public getMasterFromTeam(id) {
    if (!this.user || !this.user.team || !this.user.team.interpreters || !id) {
      return null;
    }
    if (id === this.user._id) {
      return this.user;
    }

    const eventMasters = this.user.team.eventMasters;
    for (let i = 0; i < eventMasters.length; i++) {
      if (id === eventMasters[i]._id) {
        return eventMasters[i];
      }
    }
  }

  // Used to create new team members
  public add(newUser: User, callback: Function) {
    const URI = `${environment.apiUrl}/user`;
    return this.http.post(URI, newUser).subscribe(
      (data) => {
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  // setUser
  public setUser(newUser: User) {
    if (newUser && newUser._id) {
      this.user = new User(newUser);
    } else {
      this.user = null;
    }

    this.userChange.next(this.user);
    KurentoService.isManager = this.user.isEventManager()
  }

  // This will call the findByIdAndUpdate method
  public update(newUser: User, callback: Function) {
    // saving team populated on signin
    let team;
    if (this.user.team) team = this.user.team;

    const URI = `${environment.apiUrl}/user`;
    return this.http.put(URI, newUser).subscribe(
      (data: User) => {
        // copying saved team because it is not populated on user update
        if (team) data.team = team;

        this.setUser(<User>data);
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  public updateByOthers(updates: any, callback: Function) {
    if (!this.user) {
      return null;
    }

    if (updates._id) {
      const URI = `${environment.apiUrl}/user`;
      return this.http.put(URI, updates).subscribe(
        (data: any) => {
          callback(null, data);
        },
        (err) => callback(err)
      );
    }
  }

  public getById(id: string, callback: Function) {
    const URI = `${environment.apiUrl}/user/${id}`;
    return this.http.get(URI).subscribe(
      (data: any) => {
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  // Change user's password
  public changePassword(user: User, callback: Function) {
    const URI = `${environment.apiUrl}/user/password/change`;
    return this.http.put(URI, user).subscribe(
      (data: User) => {
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  public getTeam(callback: Function, reload?: boolean) {
    if (this.user && this.user.isOwner() && !reload) {
      return callback(null, this.user.team);
    }

    const URI = `${environment.apiUrl}/user/team`;
    return this.http.get(URI).subscribe(
      (data: any) => {
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  public rechargeBalance(data: any, callback: Function) {
    if (data && data.user && data.amount && (parseInt(data.amount) > 0)) {
      const URI = `${environment.apiUrl}/user/balance/recharge`;
      return this.http.put(URI, data).subscribe(
        (res: any) => {
          this.setUser(res);
          callback(null, res);
        },
        (err) => callback(err)
      );
    }
  }

  public getAll(callback: Function) {
    const URI = `${environment.apiUrl}/user/users/list`;
    return this.http.get(URI).subscribe(
      (data: any) => {
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  public getStats(callback: Function) {
    const URI = `${environment.apiUrl}/user/users/stats`;
    return this.http.get(URI).subscribe(
      (data: any) => {
        callback(null, data);
      },
      (err) => callback(err)
    );
  }
}
